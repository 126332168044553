import React, {useState, useEffect} from 'react'
import { View, StyleSheet, Text } from 'react-native'
import {commonStyles} from '../style/commonStyle'

const style = StyleSheet.create({
  panelMargin: {
    position: "absolute",
    top: 20,
    width: "100%"
  },
  errorPanel: {
    flex: 1,
    backgroundColor: "#fcc",
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 10,
    opacity: 1,
    marginHorizontal: 20,
    marginBottom: 10,
    padding: 10
  }
});

export let displayError = () => {};

function setHook(f) {
  displayError = f;
}

export function ErrorPanel({timeout}) {
  const [errors, setErrors] = useState([]);
  
  setHook(addError);

  return (
    <View style={style.panelMargin}>
      {errors.map(text => 
        <View style={style.errorPanel} key={text}>
          <Text style={commonStyles.errorText}>
            {text}
          </Text>
        </View>
      )}
    </View>
  )
  
  function addError(e) {
    setErrors(errors => [...errors, e]);
    setTimeout(() => setErrors(errors => {
      errors.shift();
      return [...errors];
    }), timeout);
  }
}