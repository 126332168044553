import React from "react";
import {StyleSheet, Text, View, Linking, ScrollView} from "react-native";
import {palette} from "../style/palette";
import {createStackNavigator} from "@react-navigation/stack";
import {AppButton, headerBarOptions} from "../style/commonStyle";
import Hyperlink from 'react-native-hyperlink'
import moment from "moment";
import history from "../history/history";

const textColor = palette.$accent1Shade2;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  notificationInnerContainer: {
    paddingHorizontal: 20
  },
  notificationDetails: {
    backgroundColor: palette.$primary,
    borderRadius: 10,
    padding: 20,
    justifyContent: "center",
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    marginVertical: 10,
  },
  title: {
    fontFamily: 'metropolis-bold',
    color: textColor,
    alignItems: 'center',
    fontSize: 18.5,
    marginBottom: 10,
  },
  time: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontFamily: 'metropolis-bold',
    color: textColor,
    fontSize: 10,
  },
  body: {
    fontFamily: 'metropolis-semi-bold',
    fontSize: 14,
    color: palette.$accent1Shade3,
    padding: 10,
  },
  contactUs: {
    alignItems: 'center',
    marginBottom: 20
  },
  contactUsText: {
    fontFamily: 'metropolis-semi-bold',
    fontSize: 14,
    color: palette.$accent1Shade3,
    marginTop: 40,
    marginBottom: 20
  },
  link: {
    fontFamily: 'metropolis-bold',
    fontSize: 16,
    color: palette.$accent1Shade2,
    textDecorationLine: "underline"
  }
});


const Stack = createStackNavigator();

export function NotificationScreen() {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="Notification"
                    component={Notification}
                    options={{
                      title: "Notification",
                    }}
      />
    </Stack.Navigator>
  );
}

export function Notification({route}) {
  return (
    <ScrollView style={styles.container}>
      <View style={styles.notificationInnerContainer}>
        {route.params &&
          <NotificationCard title={route.params?.title} body={route.params?.body}/>
        }
        <View style={styles.contactUs}>
          <Text style={styles.contactUsText}>Please click on the button below if you want to talk to us</Text>
          <AppButton
            title={'Talk to us'}
            onPress={() => history().push("Chat")}
          />
        </View>
      </View>
    </ScrollView>
  )
}

export function NotificationCard({title, body, time, truncateContent}) {
  let notificationDate;
  if (time) notificationDate = moment(time).format("DD/MM/YYYY hh:mm");
  return (
    <View style={styles.notificationDetails}>
      <Text style={styles.title}>{title}</Text>
      <Hyperlink linkDefault={true} linkStyle={styles.link}>
        <Text style={styles.body}>
          {truncateContent ? truncate(body, 50) : body}
        </Text>
      </Hyperlink>
      <Text style={styles.time}>{notificationDate}</Text>
    </View>
  );
}

function truncate(str, n) {
  return str.length > n ? str.substring(0, n) + "..." : str;
}
