import React, {Image, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useEffect, useState} from "react";
import {conversationApi} from "../api/conversationApi";
import {palette} from "../style/palette";
import history from "../history/history";

const stepLeft = {
  justifyContent: "flex-start"
}

const stepRight = {
  justifyContent: "flex-end"
}

const stepCenter = {
  justifyContent: "center"
}

const style = StyleSheet.create({
  itinerary: {
    
  },
  step: {
    width: "100%",
    flexDirection: "row",
    borderBottomColor: palette.$accent1Tint1,
    borderBottomWidth: 1,
    padding: 20,
  },
  step0: stepCenter,
  step1: stepLeft,
  step2: stepCenter,
  step3: stepRight,
  scenario: {
    flexDirection: "column",
    alignItems: "center",
  },
  scenarioBtn: {
    borderRadius: 16,
    backgroundColor: palette.$accent1Shade2,
    width: 100,
    height: 100,
    justifyContent: "center",
    overflow: "hidden",
  },
  scenarioLabel: {
    width: 150,
    fontSize: 10,
    textAlign: "center",
    color: palette.$accent1Shade2,
    fontWeight: "700",
    marginTop: 10
  },
  ordinal: {
    color: "white",
    fontSize: 40,
    textAlign: "center"
  },
  icon: {
    minHeight: "100%",
    minWidth: "100%",
  }
});

export function Itinerary({route}) {
  const [itinerary, setItinerary] = useState(null);
  
  useEffect(() => {
    loadItinerary();
  }, [])
  
  async function loadItinerary() {
    setItinerary(await conversationApi.getItineraryById(route.params.itinerary._id));
  }
  
  return (
    <ScrollView style={style.itinerary}>
      {itinerary && itinerary.steps.sort((s1, s2) => s1.ordinal - s2.ordinal).map((s, idx) => 
        <View style={[style.step, style["step" + idx % 4]]} key={idx}>
          <Step step={s}/>
        </View>
      )}
    </ScrollView>
  );
  
  function Step({step}) {
    const scenario = itinerary.scenariosById[step.scenarioId];
    return (
      <View style={style.scenario}>
        <TouchableOpacity style={style.scenarioBtn} onPress={() => history().push("Scenario", {scenarioId: step.scenarioId})}>
          {scenario.icon ?
            <Image style={style.icon} source={{uri: scenario.icon}}/> :
            <Text style={style.ordinal}>{step.ordinal}</Text>
          }
        </TouchableOpacity>
        <Text style={style.scenarioLabel}>
          {scenario.label}
        </Text>
      </View>
    );
  }
}
