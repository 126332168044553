import React, {useEffect, useRef} from 'react';
import {
  Animated,
  StyleSheet,
  View,
  Text,
  Dimensions,
  Easing,
  Pressable
} from 'react-native';
import {palette} from "../style/palette";
import CongratsBanner from "../../assets/vocab/results/congrats_banner.svg"
import StarCenter from "../../assets/vocab/results/star_center.svg"
import { analytics } from '../analytics/analytics';

const {height: windowHeight, width: windowWidth} = Dimensions.get('window');

export function Result({resultText, onClose}) {
  const scale = useRef(new Animated.Value(0)).current
  useEffect(() => {
    Animated.timing(
      scale,
      {
        toValue: 1,
        useNativeDriver: true,
        duration: 1200,
        easing: Easing.bounce
      }
    ).start();
    analytics.track("result.shown", {result: resultText});
  }, [])
  return (
    <Pressable style={styles.outerContainer} onPress={onClose}>
      <View style={styles.resultContainer}>
        <View style={styles.starsView}>
          <Animated.View style={[styles.starLeft, {
            transform: [{
              scale: scale.interpolate({
                inputRange: [0, .33, .66, 1],
                outputRange: [0, .9, .9, .9]
              }),
            }, {
              rotate: '-7deg'
            }]
          }]}>
            <StarCenter/>
          </Animated.View>
          <Animated.View style={[styles.starCenter, {
            transform: [{
              scale: scale.interpolate({
                inputRange: [0, .33, .66, 1],
                outputRange: [0, 0, 1.2, 1.2]
              }),
            }]
          }]}>
            <StarCenter/>
          </Animated.View>
          <Animated.View style={[styles.starRight, {
            transform: [{
              scale: scale.interpolate({
                inputRange: [0, .33, .66, 1],
                outputRange: [0, 0, 0, .9]
              })
            }, {
              rotate: '7deg'
            }]
          }]}>
            <StarCenter/>
          </Animated.View>
        </View>
        <CongratsBanner width={'140%'} height={"40%"}/>
        <Text style={styles.rankText}>
          {resultText}
        </Text>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  outerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: windowHeight,
    width: windowWidth,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: "absolute",
    zIndex: 1
  },
  resultContainer: {
    marginBottom: 20,
    width: windowWidth * .8,
    height: windowHeight * .65,
    borderRadius: windowWidth * .2,
    backgroundColor: palette.$accent1Tint2,
    borderWidth: 15,
    borderColor: palette.$accent1Tint1,
    justifyContent: "flex-start",
    alignItems: "center",
    position: "absolute",
    zIndex: 1
  },
  starsView: {
    flexDirection: "row",
    position: "absolute",
    top: windowHeight * -.05
  },
  starRight: {
    top: windowHeight * -.017,
    right: windowWidth * -.01
  },
  starLeft: {
    top: windowHeight * -.017,
    left: windowWidth * -.01
  },
  starCenter: {
    top: windowHeight * -.045,
  },
  rankText: {
    color: palette.$redText,
    fontFamily: "metropolis-extra-bold",
    fontSize: 32,
  }
})
