import storage from "@react-native-async-storage/async-storage";
import {userSession} from "../api/userSession";
import {quizApi} from "../api/quizApi";
import {log} from "../logger/logger";
import {userApi} from "../api/userApi";

export async function calculateInitialNavigationRoute() {
  if (!await userSession.getToken()) {
    return {route: "OtpLogin"};
  }

  if (await isUserProfileIncomplete()) {
    return {route: "UserDetails"};
  }
  
  if (["6502a4abdf7ef1eeaf4111da", "650af1ce7cb69426d89d726b", "5cab079e61d19a59173f7b3f", "6196a9c35b2a9705189f44bf"].includes(await userSession.getUserId())) {
    return {route: "Home", params: {screen: "PracticeSelection"}};
  }

  const firstAppStart = "first.app.start";

  if (!await storage.getItem(firstAppStart)) {
    let levelAttempt;
    try {
      levelAttempt = await quizApi.getLatestLevelAttempt();
      if (!levelAttempt) {
        await storage.setItem(firstAppStart, "true");
        return {route: "Home", params: {screen: "Test"}};
      }
    } catch (e) {
      const userId = await userSession.getUserId();
      log.error(`Unable to get the latest level attempt for userId: ${userId}`);
    }
    await storage.setItem(firstAppStart, "true");
    return {route: "Home", params: {screen: "Notifications"}};
  }

  return {route: "Home"};
}

async function isUserProfileIncomplete() {
  const user = await userApi.getUser();
  return (!user.name || (user.name && user.name.includes("Provisional Account")));
}
