import {userSession} from "../api/userSession";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UnleashClient } from 'unleash-proxy-client';

const unleash = new UnleashClient({
  url: 'https://video.cerah.co/api/frontend/',
  appName: 'cerah-students',
  clientKey: 'default:production.8522ca7e39352d1d8d24c0581ebd66efd7c1bcd54fd19a274e9d9839',
  storageProvider: {
    save: (name, data) => AsyncStorage.setItem(name, JSON.stringify(data)),
    get: async (name) => {
      const data = await AsyncStorage.getItem(name);
      return data ? JSON.parse(data) : undefined;
    },
  },
});

export const featureChecker = {
  isEnabled(feature) {
    return unleash.isEnabled(feature);
  },
  setUserId(userId) {
    unleash.updateContext({ userId });
  }
}


setTimeout(async () => {
  await unleash.start();
  featureChecker.setUserId(await userSession.getUserId());
}, 0);
