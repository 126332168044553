import {Platform} from "react-native";

const env = __DEV__ ? "dev" : "prod";

const devConfig = Object.freeze({
  COURSE_SVC_BASE_URL: "https://api-dev.cerah.co", //
  QUIZ_SVC_BASE_URL: "https://api-dev.cerah.co",
  USER_SVC_BASE_URL: "https://api-dev.cerah.co/users2",
  NOTIFICATION_SVC_BASE_URL: "https://api-dev.cerah.co/notifications",
  TEACHER_SVC_BASE_URL: "https://api-dev.cerah.co",
  VOCAB_SVC_BASE_URL: "https://api-dev.cerah.co",
  CHAT_SVC_BASE_URL: "https://api-dev.cerah.co/chat",
  CONVERSATION_SVC_BASE_URL: "https://api.cerah.co",
  VOICES_SVC_BASE_URL: "https://voices.cerah.co:48017",
  AMPLTITUDE_TOKEN: "bff52ba046bac361c7ce83b5d27f5a3e",
  chatServer: {
    baseRestUrl: "https://chat-dev.cerah.co/api/v1",
    baseSocketsUrl: "wss://chat-dev.cerah.co/websocket",
  }
});

const prodConfig = Object.freeze({
  COURSE_SVC_BASE_URL: "https://api.cerah.co",
  QUIZ_SVC_BASE_URL: "https://api.cerah.co",
  USER_SVC_BASE_URL: "https://api.cerah.co/users2",
  NOTIFICATION_SVC_BASE_URL: "https://api.cerah.co/notifications",
  TEACHER_SVC_BASE_URL: "https://api.cerah.co",
  VOCAB_SVC_BASE_URL: "https://api.cerah.co",
  CHAT_SVC_BASE_URL: "https://api.cerah.co/chat",
  VIRTUAL_COACH_SVC_BASE_URL: "https://api.runpod.ai/v2/jf3acbiwletf3u",
  CONVERSATION_SVC_BASE_URL: "https://api.cerah.co",
  VOICES_SVC_BASE_URL: "https://voices.cerah.co:48017",
  AMPLTITUDE_TOKEN: "217bf178150ea3fb50aa9c53d5de8a45",
  chatServer: {
    baseRestUrl: "https://chat.cerah.co/api/v1",
    baseSocketsUrl: "wss://chat.cerah.co/websocket",
  }
});

export const config = (env === "prod") ? prodConfig : devConfig;

export const webp = Platform.OS === "android";
