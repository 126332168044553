import {Platform} from "react-native";
import React from 'react';
import Bugsnag from "./Bugsnag";

if (Platform.OS !== "web") {
  Bugsnag.start();
}

export const LoggerWrapperComponent = Platform.OS !== "web" ? Bugsnag.getPlugin('react').createErrorBoundary(React) : ({children}) => <>{children}</>;

export const log = {};

if (Platform.OS === "web" || __DEV__ ) {
  log.debug = (error, extra) => {
    console.log(error, extra);
  };
  log.info = (error, extra) => {
    console.log(error, extra);
  };
  log.warn = (error, extra) => {
    console.error(error, extra);
  };
  log.error = (error, extra) => {
    console.error(error, extra);
  };
  log.changeUserId = (userId) => {
  }
} else {
  log.debug = (error, extra) => {
    console.debug(error, extra);
  };
  log.info = (error, extra) => {
    console.log(error, extra);
  };
  log.warn = (error, extra) => {
    console.warn(error, extra);
    // Bugsnag.notify(new Error(error, {cause: extra}));
  };
  log.error = (error, extra) => {
    console.error(error, extra);
    Bugsnag.notify(new Error(error, {cause: extra}));
  };
  log.changeUserId = (userId) => {
    Bugsnag.setUser(userId);
  };
}
