import React from 'react';
import {Dimensions, Text, View, StyleSheet, Image, TouchableOpacity} from 'react-native';
import {palette} from "../style/palette";
import Carousel from 'react-native-snap-carousel';
const convoImg = require("../../assets/conversation.png");
const buttonPressImg = require("../../assets/greenButtonPress.png");
const correctionsImg = require("../../assets/corrections.png");
const privacyImg = require("../../assets/privacy.png");

const labels = [
  "You're going to have a conversation with an artificial intelligence character.",
  "Press the green button before speaking.\nThen press it again after you speak.",
  "You will see suggestions to improve your English while you talk to the character.",
  "WARNING: Your conversation is sent to our servers. Do not talk about private things."
];

const images = [
  convoImg,
  buttonPressImg,
  correctionsImg,
  privacyImg,
];

export function InstructionsAndWarning({onAccept, onReject}) {
  const {width} = Dimensions.get('window');
  return (
    <View style={style.instructions}>
      <View style={style.carousel}>
        <Carousel data={[0, 1, 2, 3]}
                  renderItem={renderSlide}
                  sliderWidth={Math.min(width, 500)}
                  itemWidth={0.8 * Math.min(width, 500)}
                  enableSnap={true}
        />
      </View>
    </View>
  )
  
  function renderSlide({index}) {
    return (
      <View style={style.slide}>
        <Image style={style.image}
               source={images[index]}/>
        <Text style={style.text}>
          {labels[index]}
        </Text>
        {index === 3 && <>
          <AcceptButton/>
          <RejectButton/>
        </>}
      </View>
    );
  }
  
  function AcceptButton() {
    return (
    <TouchableOpacity style={style.acceptBtn} onPress={onAccept}>
      <Text style={style.acceptBtnText}>Accept</Text>
    </TouchableOpacity>
    );
  }

  function RejectButton() {
    return (
    <TouchableOpacity style={style.rejectBtn} onPress={onReject}>
      <Text style={style.rejectBtnText}>go back</Text>
    </TouchableOpacity>
    );
  }
}



const style = StyleSheet.create({
  instructions: {
    flex: 1, 
    backgroundColor: palette.$accent1Shade2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  carousel: {
    height: "70%",
    maxWidth: 500,
  },
  slide: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    padding: 40,
    borderRadius: 20
  },
  image: {
    flexShrink: 1,
    width: "100%",
    height: "100%"
  },
  text: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 20,
    fontWeight: "700",
    color: palette.$accent1Shade2,
    flexShrink: 0,
  },
  acceptBtn: {
    borderRadius: 100,
    backgroundColor: palette.$accent1Shade2,
    paddingVertical: 12,
    paddingHorizontal: 40,
    marginTop: 30
  },
  acceptBtnText: {
    fontSize: 24,
    color: "#fff",
    fontWeight: "700",
    textAlign: "center"
  },
  rejectBtn: {
    width: "80%",
    marginTop: 30,
  },
  rejectBtnText: {
    fontSize: 16,
    color: palette.$accent1Shade2,
    textDecorationLine: "underline",
    textAlign: "center"
  },
});