import {Animated, Pressable, StyleSheet, Text, View} from "react-native";
import {FontAwesome5, MaterialIcons} from "@expo/vector-icons";
import {gamifyPalette, palette} from "../style/palette";
import {Spinner} from "../components/spinner";
import React, {useRef, useState} from "react";
import {analytics} from "../analytics/analytics";
import {Speech} from "../voice/speech";
import {Audio} from "expo-av";
import {log} from "../logger/logger";
import {speechToTextApi} from "../api/speechToTextApi";
import {displayError} from "../components/errorPanel";

export function SpeechRecorder({onRecord, disabled, language = "en"}) {
  const [recording, setRecording] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [savingAudio, setSavingAudio] = useState(false);
  
  const buttonScale = useRef(new Animated.Value(1)).current;
  
  if (recording && disabled) {
    cancelRecording();
    setRecording(false);
  }
  
  return (
    <View style={style.speechRecorder}>
      {!savingAudio && recording &&
        <Text style={style.timer}>{formatTime(elapsedTime)}</Text>
      }
      
      <Pressable onPress={recording ? stopRecording : startRecording} disabled={savingAudio || disabled}>
        {savingAudio ?
          <View style={style.recordBtn}><Spinner color={gamifyPalette.$neutral1} size={40}/></View> :
          <Animated.View style={[style.recordBtn, recording && style.recordBtnActive, {transform: [{scale: buttonScale}]}]}>
            <FontAwesome5 name={recording ? "stop" : "microphone"} size={40} color={gamifyPalette.$neutral1}/>
          </Animated.View>
        }
      </Pressable>
      {!savingAudio && recording &&
        <Pressable style={style.cancelRecordBtn} onPress={cancelRecording}>
          <MaterialIcons name="close" size={48} color="white"/>
        </Pressable>
      }
    </View>
  )
  
  function startTimer() {
    setElapsedTime(0);
    setTimeout(countTime, 1000);
  }
  
  function countTime() {
    setRecording(recording => { //hack to access state from closure
      if (recording) {
        setElapsedTime(elapsedTime => elapsedTime + 1);
        setTimeout(countTime, 1000);
      }
      return recording;
    });
  }
  
  async function startRecording() {
    analytics.track("speech.startRecording");
    Speech.stopAll();
    try {
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });
      const {recording} = await Audio.Recording.createAsync(Audio.RecordingOptionsPresets.HIGH_QUALITY);
      setRecording(recording);
      startTimer();
      animateButton();
    } catch (err) {
      log.error('Failed to start recording', err);
    }
  }
  
  async function stopRecording() {
    analytics.track("speech.stopRecording");
    setSavingAudio(true);
    try {
      await stopAndUnloadRecording();
      const uri = recording.getURI();
      const text = await speechToTextApi.getTranscription(uri, language);
      onRecord(text);
    } catch (err) {
      log.error('Failed to stop recording and transcribe speech', err);
      displayError("Error sending speech, please try again.")
    }
    setSavingAudio(false);
    stopAnimation();
    setRecording(null);
  }
  
  async function cancelRecording() {
    analytics.track("speech.cancelRecording");
    setSavingAudio(true);
    try {
      await stopAndUnloadRecording();
    } catch (e) {}
    setSavingAudio(false);
    stopAnimation();
    setRecording(null);
  }
  
  async function stopAndUnloadRecording() {
    await Audio.setAudioModeAsync({ allowsRecordingIOS: false });
    await recording.stopAndUnloadAsync();
  }
  
  function animateButton() {
    Animated.loop(
    Animated.sequence([
      Animated.timing(buttonScale, {
        toValue: 1.15,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.timing(buttonScale, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
    ]),
    ).start();
  }
  
  function stopAnimation() {
    Animated.loop(
    Animated.timing(buttonScale, {
      toValue: 1,
      duration: 0,
      useNativeDriver: true,
    }),
    ).stop();
  }
  
  function formatTime(time) {
    const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
}


const style = StyleSheet.create({
  speechRecorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  timer: {
    position: "absolute",
    fontSize: 32,
    fontWeight: 'bold',
    right: 0
  },
  recordBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    padding: 20,
    height: 100,
    width: 100,
    backgroundColor: gamifyPalette.$colour5,
  },
  recordBtnActive: {
    backgroundColor: gamifyPalette.$colour2
  },
  cancelRecordBtn: {
    position: "absolute",
    left: 0,
    padding: 8,
    borderRadius: 64,
    width: 64,
    height: 64,
    flexShrink: 1,
    justifyContent: "center",
    backgroundColor: gamifyPalette.$colour4
  },
})