import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, FlatList, TouchableOpacity, View} from "react-native";
import {headerBarOptions} from "../style/commonStyle";
import {createStackNavigator} from "@react-navigation/stack";
import {notificationApi} from '../api/notificationApi';
import {NotificationCard} from './notification';
import history from "../history/history";
import {log} from "../logger/logger";

const Stack = createStackNavigator();

export function NotificationsHistoryScreen() {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="Notifications"
                    component={NotificationsHistory}
                    options={{
                      title: "Notifications",
                    }}
      />
    </Stack.Navigator>
  );
}

function NotificationsHistory({navigation}) {
  const [notifications, setNotifications] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    return navigation.addListener('focus', retrieveNotifications);
  }, []);

  async function retrieveNotifications() {
    setRefreshing(true);
    try {
      const notificationHistory = await notificationApi.getNotificationHistory();
      notificationHistory.reverse();
      setNotifications(notificationHistory);
    } catch (e) {
      log.error("Failed to refresh notifications list", e);
    } finally {
      setRefreshing(false);
    }
  }

  const renderNotification = ({ item }) => (
    <TouchableOpacity activeOpacity={0.8} style={styles.touchable} onPress={() => onNotificationPress(item)}>
      <NotificationCard title={item.title} body={item.body} time={item.creationTime} truncateContent={true}/>
    </TouchableOpacity>
  );

  function onNotificationPress(n) {
    history().push("NotificationScreen", {screen: "Notification", params: {title: n.title, body: n.body}});
  }

  return (
    <SafeAreaView style={styles.container}>
      <View>
        <FlatList
          data={notifications}
          keyExtractor={n => n._id}
          renderItem={renderNotification}
          refreshing={refreshing}
          onRefresh={retrieveNotifications}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  touchable: {
    paddingHorizontal: 20
  }
});
