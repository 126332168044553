import {api} from "./api";

export const courseApi = {
  getMeetings: async () => {
    return api.courseSvcRequest('GET', '/student/meetings');
  },
  getCourseHistories: async() => {
    return api.courseSvcRequest('GET', '/student/courseHistories');
  },

  rateMeeting: async ({meetingId, teacherId, rating, comment}) => {
    return api.courseSvcRequest('POST', '/meetingRatings', {meetingId, teacherId, rating, comment});
  },

  createPaymentLink: async (courseId) => {
    return api.courseSvcRequest('POST', '/student/paymentLinks', {courseId});
  }
}
