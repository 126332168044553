import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { diffWords } from 'diff';

export const DiffViewer = ({ text1, text2 }) => {
  const differences = diffWords(text1, text2, {ignoreWhitespace: true, ignoreCase: true, comparator: compareIgnoringPunctuation});

  return (
    <View style={styles.container}>
      {differences.map((diff, index) => (
        <Text key={index} style={[styles.text, diff.added ? styles.added : diff.removed ? styles.removed : styles.unchanged]}>
          {diff.value + (diff.removed ? " " : "")}
        </Text>
      ))}
    </View>
  );
};

function compareIgnoringPunctuation(left, right) {
  const regex = /[.,\/#!$%\^&\*;:{}=\-_`~()]/g;
  return left.replace(regex, "").toLowerCase() === right.replace(regex, "").toLowerCase();
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  text: {
    position: "relative",
    fontSize: 18,
  },
  added: {
    fontWeight: 'bold',
    color: "#080"
  },
  removed: {
    color: "#aaa",
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid'
  },
  unchanged: {
    fontWeight: 'bold',
  }
});