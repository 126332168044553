import {userApi} from "../api/userApi";
import {Text, View} from "react-native";
import React, {useEffect, useRef, useState} from "react";
import {WebView} from './webView/webView'
import {Spinner} from "../components/spinner";

export function TestWebView(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    retrieveUser()
  }, []);

  async function retrieveUser() {
    const user = await userApi.getUser();
    setUser(user);
  }

  if (!user) return null;

  const uri = `https://${__DEV__ ? "dev." : "www."}cerah.co/test/level/intro/?continue=no${user.phone ? `&p=${user.phone.replace('+', '')}` : ""}`;

  return (
    <View style={{flex: 1}}>
      {user &&
        <WebView
          style={{flex: 1}}
          source={{uri}}
        />
      }

      {!user && <Spinner/>}
    </View>
  );

}
