import {api} from "./api";
import {userSession} from "./userSession";

export const chatApi = {
  getSupportRoomId: async () => {
    const userId = await userSession.getUserId();
    const chatUser = await api.chatSvcRequest('GET', `/student/users/${userId}`);
    return chatUser.supportRoomId;
  },
};
