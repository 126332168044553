import {config} from "../config";

import * as Amplitude from "@amplitude/analytics-react-native";
import {userSession} from "../api/userSession";
import {log} from "../logger/logger";

Amplitude.init(config.AMPLTITUDE_TOKEN);

setTimeout(async function () {
  let userId;
  try {
    userId = await userSession.getUserId();
  } catch (e) {
    log.error("Failed to retrieve userId from userSession while initializing Amplitude", e);
  }

  await Amplitude.logEvent("userId", {userId});
  if (userId) {
    try {
      await Amplitude.setUserId(userId);
    } catch (e) {
      log.error(`Failed to set Amplitude userId for user ${userId}`, e);
    }
  }
}, 0);

export default Amplitude;