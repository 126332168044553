import React, {useState, useEffect} from 'react'
import {View, Platform, Text, TextInput, ActivityIndicator, Image, Dimensions, ScrollView} from 'react-native';
import {AppButton, commonStyles} from "../style/commonStyle";
import {style} from "./loginStyle";
import {Spinner} from "../components/spinner";
import {displayError} from '../components/errorPanel';
import { userApi } from '../api/userApi';
import {calculateInitialNavigationRoute} from "./appStartupHandler";
import history from "../history/history";
import {userSession} from "../api/userSession";

export function UserDetails() {
  const [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [validationError, setValidationError] = useState();

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    const user = await userApi.getUser();
    setUser(user);
  }

  return (
    <View style={commonStyles.page}>
      <View style={style.emailLoginForm}>
        <Text style={commonStyles.headingText}>Your Info</Text>
        <View>
          <View style={style.emailLoginInputContainer}>
            <Image
              style={style.textFieldIcon}
              source={require('../../assets/icon_name.png')}
            />
            <TextInput style={style.emailLoginInput}
                      onChangeText={name => {
                        setName(name);
                      }}
                      autoCapitalize="none"
                      placeholder="Name"/>
          </View>
          <View style={style.emailLoginInputContainer}>
            <Image
              style={style.textFieldIcon}
              source={require('../../assets/icon_email.png')}
            />
            <TextInput style={style.emailLoginInput}
                      onChangeText={email => {
                        setEmail(email);
                      }}
                      keyboardType="email-address"
                      autoCapitalize="none"
                      placeholder="Email"/>
          </View>
        </View>
        <View style={{alignItems: "center", paddingTop: 30}}>
          <AppButton onPress={() => submitUserDetails()} title={!submitting ? "Continue" : ""}>
            {submitting && <Spinner/>}
          </AppButton>
        </View>
      </View>
      <Image
        style={commonStyles.backgroundDoodle}
        source={require('../../assets/backgroundDoodle.png')}
      />
    </View>
  );

  function validateFields() {
    if (!name || !email) {
      displayError("Fields cannot be empty");
      return false;
    }
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      displayError("Invalid email");
      return false;
    }

    return true;
  }

  async function submitUserDetails() {
    setSubmitting(true);
    if (!validateFields()) {
      setSubmitting(false);
      return;
    }

    if (user) {
      user.name = name;
      user.email = email;
      try {
        await userApi.updateUser(user);
        await userSession.updateName(user.name);
        const {route, params} = await calculateInitialNavigationRoute();
        history().reset(route, params);
      } catch (e) {
        console.log("Error updating user profile", e);
      }
    }
    setSubmitting(false);
  }


}
