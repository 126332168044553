import React, { useState, useEffect } from 'react';
import {Pressable, StyleSheet, View, Image, TouchableOpacity, Text} from 'react-native';
import {Spinner} from "../components/spinner";
import {gamifyPalette, palette} from "../style/palette";
import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import history from '../history/history';
import {Speech} from "../voice/speech";
import {PulsatingCircle} from '../components/pulsatingCircle';
import {conversationApi} from "../api/conversationApi";
import {sleep} from "../sleep/sleep";
import {userSession} from "../api/userSession";
import {DiffViewer} from "../languageDrills/diffViewer";
import {SpeechRecorder} from "../recorder/speechRecorder";
import {InstructionsAndWarning} from "./instructionsAndWarning";
import {log} from "../logger/logger";
import {userApi} from "../api/userApi";
import {Dimensions} from 'react-native'
const avatar = require("../../assets/Sam.png");

const vh = Dimensions.get("window").height;
const vw = Dimensions.get("window").width;

let lastReply;
let convoId;

const firstStudentWords = "";

let privacyAcceptedCallback = function (){}; //Replaced dynamically, do not use

let privacyAcceptance;

let numberOfReplies = 0;

let language = "en";

const bracketMatcher = /\[[^\]\[]*]/g;

export function Scenario({route}) {
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [promptSpeaking, setPromptSpeaking] = useState();
  const [corrections, setCorrections] = useState([]);
  const [scenario, setScenario] = useState({});
  const [descriptionRead, setDescriptionRead] = useState(false);

  useEffect(() => {
    checkPrivacyAcceptance();
    loadScenario();
  }, []);

  useEffect(() => {
    if (descriptionRead) startConvo();
  }, [descriptionRead])
  
  async function checkPrivacyAcceptance() {
    if (await userSession.isPrivacyWarningAccepted()) {
      privacyAcceptance = Promise.resolve();
      setPrivacyAccepted(true)
    } else {
      privacyAcceptance = new Promise((resolve, reject) => {
        privacyAcceptedCallback = async () => {
          setPrivacyAccepted(true);
          await userSession.acceptPrivacyWarning();
          resolve();
        }
      });
    }
  }
  
  async function loadScenario() {
    try {
      setScenario(await conversationApi.getScenarioById(route.params.scenarioId));
      setLoading(false);
    } catch (e) {
      log.error(`Error while trying to load scenario ${route?.params?.scenarioId}`, e);
    }
  }
  
  async function startConvo() {
    try {
      language = (await userApi.getAppSettings()).foreignLanguage;
      numberOfReplies = 0;
      const convo = await conversationApi.startConversation({
        name: (await userSession.getActiveUserName()).split(" ")[0],
        language,
        scenarioId: route.params.scenarioId
      });
      convoId = convo._id;
      await getWords(convoId, firstStudentWords);
    } catch (e) {
      log.error("Error while trying to start conversation", e);
    }
  }
  
  if (!privacyAccepted) {
    return <InstructionsAndWarning onAccept={privacyAcceptedCallback} onReject={history().goBack}/>;
  }
  
  return (
    <View style={style.scenario}>
      <Pressable
        style={style.backArrow}
        onPress={() => {
          history().goBack();
          Speech.stopAll();
        }}>
        {numberOfReplies >= 3 ? <EndSessionButton onPress={finishConversation}/> : <AntDesign name="arrowleft" size={32} color="black"/>}
      </Pressable>
      {loading && <View style={{flex: 1, justifyContent: "center"}}>
        <Spinner color="#000" size={50}/>
      </View>}
      {!loading &&
        <View style={style.scene}>
          <Image style={style.image} source={scenario?.mainImage ? {uri: scenario.mainImage} : avatar}/>
  
          {descriptionRead && <>
          
            <View style={style.voiceIcon}>
              <View style={{zIndex: 1, opacity: promptSpeaking ? 1 : 0}}>
                <MaterialIcons name="volume-up" size={50} color="white"/>
              </View>
              <View style={{position: "absolute", top: -25, left: -25}}>
                {promptSpeaking && <PulsatingCircle color={gamifyPalette.$colour4} duration={1400}/>}
              </View>
            </View>
            
            {!promptSpeaking && <ReplayButton onPress={repeatLastReply}/>}
        
            {corrections.length > 0 && <View style={style.corrections}>
              {corrections.map(correction =>
                <DiffViewer text1={correction.original} text2={correction.corrected}/>)
              }
            </View>}
            
            <View style={{...style.bottomButtonsContainer, opacity: promptSpeaking ? 0 : 1}}>
              <SpeechRecorder onRecord={text => getWords(convoId, text)} disabled={promptSpeaking} language={language}/>
            </View>
          </>}
          
          {!descriptionRead && <ScenarioDescription scenario={scenario} onDescriptionRead={() => setDescriptionRead(true)}/>}
        </View>
      }
    </View>
  );
  
  async function getWords(convoId, studentWords) {
    setCorrections([]);
    let sentence = {isLastSentence: true};
    
    lastReply = "";
    
    do {
      try {
        setPromptSpeaking(true);
        sentence = await conversationApi.getReply(convoId, studentWords);
        if (studentWords !== "") numberOfReplies++;
        lastReply += sentence.reply;
        if (sentence.corrections.length > 0) {
          setCorrections(sentence.corrections);
        }
        await privacyAcceptance;
        Speech.sayCool(sentence.reply.replace(bracketMatcher, ""), null, 1);
      } catch (e) {
        log.error("Could not utter sentence", e);
        setPromptSpeaking(false);
        sentence = {isLastSentence: true};
      }
      studentWords = "";
    } while (!sentence.isLastSentence);
    console.log(lastReply);
    while (await Speech.isSpeakingCool()) {
      await sleep(200);
    }
    setPromptSpeaking(false);
  }
  
  async function repeatLastReply() {
    setPromptSpeaking(true);
    await Speech.sayCool(lastReply.replace(bracketMatcher, ""), null, 0.8);
    while (await Speech.isSpeakingCool()) {
      await sleep(200);
    }
    setPromptSpeaking(false);
  }
  
  function finishConversation() {
    history().replace("ConvoAssessment", {
      conversationId: convoId,
      scenario
    });
  }
}

function ReplayButton({onPress}) {
  return (
    <TouchableOpacity style={style.replayBtn} onPress={onPress}>
      <MaterialIcons name="loop" size={50} color="white"/>
    </TouchableOpacity>
  );
}

function EndSessionButton({onPress}) {
  return (
    <TouchableOpacity onPress={onPress} style={style.endSessionBtn}>
      <Text style={style.endSessionBtnText}>End conversation</Text>
    </TouchableOpacity>
  );
}

function ScenarioDescription({scenario, onDescriptionRead}) {
  return (<View style={style.scenarioDescription}>
    <Text style={style.descriptionText}>{scenario.description}</Text>
    <StartButton onPress={onDescriptionRead}/>
  </View>);
}

function StartButton({onPress}) {
  return (
    <TouchableOpacity style={style.startBtn} onPress={onPress}>
      <Text style={style.startBtnText}>Start</Text>
    </TouchableOpacity>
  );
}


const columnWidth = 500;

const style = StyleSheet.create({
  scenario: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    backgroundColor: gamifyPalette.$neutral1,
    justifyContent: "space-between",
  },
  scene: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  voiceIcon: {
    position: "absolute",
    top: "20%",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: vw,
    height: vh,
    minWidth: vw,
    minHeight: vh,
  },
  bottomButtonsContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    width: "100%",
    maxWidth: columnWidth,
    position: "relative",
    marginTop: 40,
    paddingVertical: 40,
    paddingHorizontal: 20,
  },
  corrections: {
    maxWidth: columnWidth,
    display: "flex",
    backgroundColor: palette.$accent1Tint2,
    borderRadius: 20,
    justifyContent: "stretch",
    padding: 20
  },
  help: {
    position: "absolute",
    top: 10,
    right: 10
  },
  backArrow: {
    position: "absolute",
    top: 20,
    left: 10,
    zIndex: 1000,
  },
  button: {
    backgroundColor: gamifyPalette.$colour4
  },
  endSessionBtn: {
    backgroundColor: "#c00",
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 100,
  },
  endSessionBtnText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "700"
  },
  replayBtn: {
    position: "absolute", 
    top: 25, 
    right: 25,
    padding: 10,
    borderRadius: 100,
    backgroundColor: "black"
  },
  scenarioDescription: {
    position: "absolute",
    top: "10%",
    bottom: "10%",
    borderRadius: 20,
    opacity: 0.8,
    backgroundColor: "#fff",
    margin: 20,
    justifyContent: "center",
    alignItems: "center"
  },
  descriptionText: {
    color: palette.$accent1Shade2,
    fontWeight: "700",
    fontSize: 12,
    padding: 20,
  },
  startBtn: {
    margin: 20,
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderRadius: 100,
    backgroundColor: palette.$accent1Shade2
  },
  startBtnText: {
    fontSize: 14,
    fontWeight: "700",
    color: "#fff"
  }
})