import React from "react";
import {headerBarOptions} from "../style/commonStyle";
import {View, Text, TouchableOpacity, StyleSheet, Image, ScrollView} from "react-native";
import {palette} from "../style/palette";
import history from "../history/history";
import {createStackNavigator} from "@react-navigation/stack";
import {featureChecker} from "../featureEnabling/featureEnabling";

const Stack = createStackNavigator();

export function PracticeSelectionScreen({}) {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="Practice"
                    component={PracticeSelection}
                    options={{
                      title: "Practice",
                    }}
      />
    </Stack.Navigator>
  );
}

function PracticeSelection() {
  return (
    <ScrollView style={styles.container}>
      <View style={styles.modesContainer}>
        {featureChecker.isEnabled("virtualcoach") &&
          <ModeCard
            title={"Speaking"}
            icon={<Image style={styles.iconImage} source={require("../../assets/virtualCoach/virtualCoach.png")} resizeMode={"contain"}/>}
            onPress={() => {
              history().push("Itineraries")
            }}/>
        }
        <ModeCard 
          title={"Structure"}
          icon={<Image style={styles.iconImage} source={require("../../assets/drills/structure.png")} resizeMode={"contain"}/>} 
          onPress={() => {
            history().push("DrillSelection")
          }}/>
        <ModeCard 
          title={"Vocabulary"}
          icon={<Image style={styles.iconImage} source={require("../../assets/vocab/vocabulary.png")} resizeMode={"contain"}/>} 
          onPress={() => {
            history().push("FlashCards", {mode: vocabModes.PRACTICE_MODE})
          }}/>
      </View>
    </ScrollView>
  );
}


function ModeCard({icon, title, onPress}) {
  return (
    <TouchableOpacity style={styles.modeCard} onPress={() => onPress()}>
      {icon}
      <Text style={styles.modeCardTitle}>{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  modeCard: {
    flex: 1,
    backgroundColor: palette.$primary,
    margin: 7,
    height: 250,
    minWidth: 160,
    maxWidth: 180,
    borderRadius: 20,
    elevation: 5,
    justifyContent: "center",
    alignItems: "center"
  },
  modesContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "column",
    // paddingLeft: 10,
    // paddingRight: 10,
    // paddingTop: 30,
  },
  modeCardTitle: {
    fontFamily: "metropolis-extra-bold",
    fontSize: 18,
    padding: 20,
    color: palette.$accent1Shade2,
    textAlign: "center"
  },
  iconImage: {
    height: "50%",
    width: "80%"
  }
});

export const vocabModes = Object.freeze({
  NONE: undefined,
  CHALLENGE_MODE: "challengeMode",
  PRACTICE_MODE: "practiceMode",
  drillMode: "drillMode"
});
