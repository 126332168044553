import React from "react";
import {Dimensions, StyleSheet, Text, TouchableOpacity} from "react-native";
import {palette} from "./palette";

const vw = Dimensions.get('window').width;
const vh = Dimensions.get('window').height;

export const commonStyles = StyleSheet.create({
  infoText: {
    textAlign: "center",
    color: palette.$greyText,
    paddingVertical: 10,
  },
  errorText: {
    textAlign: "center",
    color: palette.$redText,
    paddingVertical: 10,
  },
  linkText: {
    color: palette.$accent1Shade2,
    fontWeight: 'bold',
  },
  headingText: {
    fontFamily: 'metropolis-extra-bold',
    color: palette.$accent1Shade2,
    fontSize: 22,
    textAlign: "center"
  },
  page: {
    backgroundColor: "white",
    flex: 1,
    alignItems: "center",
    height: vh
  },
  appButtonContainer: {
    backgroundColor: palette.$accent1Shade2,
    width: "100%",
    maxWidth: 400,
    alignItems: "center",
    borderRadius: 30,
    flexDirection: "row",
    justifyContent: "center",
    minHeight: 40
  },
  appButtonText: {
    color: "white",
    fontFamily: 'metropolis-bold',
    paddingVertical: 12,
    fontSize: 16
  },
  backgroundDoodle:{
    height: vh * .21,
    width: vw,
    resizeMode: "cover",
    zIndex: -100,
  },
  backgroundDoodleBig:{
    height: vh * .25,
    width: vw,
    resizeMode: "cover",
    zIndex: -100,
  }
});

export const headerBarOptions = {
  headerStyle: {
    backgroundColor: __DEV__ ? "#ccc" : "#fff",
    height: 120,
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTintColor: palette.$accent1Shade3,
  headerTitleStyle: {
    fontFamily: "metropolis-extra-bold",
    fontSize: 32,
    padding: 10,
    marginTop: 20,
  },
};

export const AppButton = ({onPress, title, style, children}) => (
  <TouchableOpacity onPress={onPress} style={[commonStyles.appButtonContainer, style && {...style}]}>
    <Text style={commonStyles.appButtonText}>{title}</Text>
    {children}
  </TouchableOpacity>
);
