import React from "react";
import {userApi} from "../api/userApi";
import {style} from "./loginStyle";
import {View, Text, TextInput, Button} from 'react-native';
import {palette} from "../style/palette";
import history from "../history/history";
import {userSession} from "../api/userSession";


export class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curPassword: "",
      newPassword: "",
      confirmedPwd: ""
    };
    this.submitClicked = this.submitClicked.bind(this);
    this.currentPwdChanged = this.currentPwdChanged.bind(this);
    this.newPwdChanged = this.newPwdChanged.bind(this);
    this.confirmPwdChanged = this.confirmPwdChanged.bind(this);
  }

  render() {
    //TODO: validation in the form
    return (
      <View style={style.login}>
        {this.state.error &&
          <View><Text style={style.error}>{this.state.error}</Text></View>
        }
        <View><Text>Change your password</Text></View>
        <TextInput style={style.input}
                   onChangeText={this.currentPwdChanged}
                   secureTextEntry={true}
                   placeholder="your current password"/>
        <TextInput style={style.input}
                   onChangeText={this.newPwdChanged}
                   secureTextEntry={true}
                   placeholder="your new password"/>
        <TextInput style={style.input}
                   onChangeText={this.confirmPwdChanged}
                   secureTextEntry={true}
                   placeholder="confirm new password"/>
        <View style={style.loginBtn}>
          <Button onPress={this.submitClicked} title="Submit" color={palette.$accent1Shade2}/>
        </View>
      </View>
    );
  }

  currentPwdChanged(curPassword) {
    this.setState({curPassword});
  }

  newPwdChanged(newPassword) {
    this.setState({newPassword});
  }

  confirmPwdChanged(confirmedPwd) {
    this.setState({confirmedPwd});
  }

  async submitClicked() {
    const st = this.state;
    if (st.newPassword !== st.confirmedPwd) {
      this.setState({error: "Passwords don't match"});
      return;
    }
    await userApi.changePwd({
      userId: await userSession.getUserId(),
      curPassword: this.state.curPassword,
      newPassword: this.state.newPassword
    });
    this.props.navigation.navigate('Login');
  }
}