import React from "react";
import {cardScheduler} from "../scheduling/cardSchedulingSvc";
import {FAILED} from "../scheduling/cardSchedulingSvc";
import {CORRECT} from "../scheduling/cardSchedulingSvc";
import {Flashcard} from "./flashcard";
import {Speech} from "../voice/speech";
import {TextInput, Animated, StyleSheet, View, Text, TouchableOpacity, Dimensions, Image, PlatformColor} from 'react-native';
import {palette} from "../style/palette";
import {AntDesign} from "@expo/vector-icons";
import {analytics} from "../analytics/analytics";
import {useEffect, useRef} from "react";
import {useState} from "react";
import {vocabularyApi} from "../api/vocabularyApi";
import {userApi} from "../api/userApi";
import {log} from "../logger/logger";


const iconCornerStyle = {
  alignItems: "center",
  justifyContent: "center",
  fontSize: 30,
  width: 50,
  height: 50,
  borderRadius: 50,
};

const iconStyles = {
  sizeIconTop: 25,
  sizeIconBottom: 35,
  color: "white",
  colorWrong: "red",
  colorCorrect: "green",
};

const topSectionHeightPct = 75;

const background = StyleSheet.create({
  bgnd: {
    flex: 1,
    backgroundColor: "white",
  },
  topSection: {
    position: "absolute",
    backgroundColor: palette.$accent3,
    bottom: `${100 - topSectionHeightPct}%`,
    borderBottomRightRadius: 110,
    borderBottomLeftRadius: 110,
    width: "100%",
    height: "100%",
    zIndex: 0,
    overflow: "hidden"
  },
  bottomSection: {
    position: "absolute",
    backgroundColor: "white",
    bottom: 0,
    width: "100%",
    height: `${100 - topSectionHeightPct}%`,
    zIndex: -1,
  },
  cloud1: {
    position: "absolute",
    zIndex: 100,
    width: 60,
    height: 45,
    left: 30,
    top: "25%",
    resizeMode: "contain",
  },
  cloud2: {
    position: "absolute",
    zIndex: 100,
    width: 130,
    height: 100,
    right: -40,
    top: "25%",
    resizeMode: "contain",
  },
  cloud3: {
    position: "absolute",
    zIndex: 100,
    width: 120,
    height: 100,
    left: -45,
    top: "65%",
    resizeMode: "contain",
  },
  cloud4: {
    position: "absolute",
    zIndex: 100,
    width: 90,
    height: 80,
    left: -40,
    bottom: "5%",
    resizeMode: "contain",
  },
  cloud5: {
    position: "absolute",
    zIndex: 100,
    width: 120,
    height: 100,
    right: -40,
    bottom: "50%",
    resizeMode: "contain",
  },
})

const {height: windowHeight, width: windowWidth} = Dimensions.get('window');
const cardWidth = windowWidth > windowHeight ? windowHeight * .4 : windowWidth * .6;

const buttonStyle = {
  height: 50,
  width: 50,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: palette.$accent1,
  elevation: 7,
  borderRadius: 50,
};

export const style = StyleSheet.create({
  fcBody: {
    position: "absolute",
    alignItems: "center",
    height: "100%",
    width: "100%",
    zIndex: 100,
  },

  topElementsContainer: {
    height: `${topSectionHeightPct}%`,
    justifyContent: "center",
    alignItems: "center",
  },

  buttonPanelTop: {
    flexDirection: "row",
    maxWidth: "80%",
    justifyContent: "space-between",
    marginBottom: 20,
    width: cardWidth,
  },

  buttonPanelBottomContainer: {
    height: `${100 - topSectionHeightPct}%`,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonPanelBottom: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: palette.$accent3,
    padding: 25,
    maxWidth: "80%",
    width: 240,
    borderRadius: 50,
    elevation: 7,
  },

  buttonWrong: {
    ...buttonStyle,
    color: "red",
    borderColor: "red",
  },

  buttonCorrect: {
    ...buttonStyle,
    color: "green",
    borderColor: "green",
  },

  score: {
    fontSize: 15,
    color: palette.$yellow,
    fontWeight: 'bold',
  },

  score2: {
    fontSize: 32,
    color: "white",
    fontWeight: 'bold'
  },

  iconSwap: {
    ...iconCornerStyle,
    left: 0,
    top: 0,
    backgroundColor: palette.$red,
    elevation: 4,
  },

  iconAdd: {
    ...iconCornerStyle,
    top: 0,
    right: 0,
    backgroundColor: palette.$yellow,
  },

  textScore: {
    fontSize: 20,
    fontWeight: "500",
    color: "white",
  },

  splash: {
    zIndex: 100,
    width: 50,
    height: 40,
    resizeMode: "contain",
  },
});


export function Flashcards(props) {
  const dropDown = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

  useEffect(() => {
    Animated.spring(
      dropDown,
      {
        toValue: 1,
        bounciness: 18,
        useNativeDriver: true
      }
    ).start();
  }, [])

  return (
    <View style={background.bgnd}>
      <Animated.View style={[background.topSection, {
        transform: [{
          translateY: dropDown.interpolate({
            inputRange: [0, 1],
            outputRange: [-400, 0]
          }),
        }],
      }]}>
        <Image
          style={background.cloud1}
          source={require('../../assets/vocab/cloud_dark.png')}
        />
        <Image
          style={background.cloud2}
          source={require('../../assets/vocab/cloud_dark.png')}
        />
        <Image
          style={background.cloud3}
          source={require('../../assets/vocab/cloud_dark.png')}
        />
      </Animated.View>
      <View style={background.bottomSection}>
        <Image
          style={background.cloud4}
          source={require('../../assets/vocab/cloud_light.png')}
        />
        <Image
          style={background.cloud5}
          source={require('../../assets/vocab/cloud_light.png')}
        />
      </View>
      <Cards navigation={props.navigation} />
    </View>
  )
}


const flashCardState = Object.freeze({
  FLASHCARD: "flashCard",
  EDIT_WORD: "editWord",
  ADD_WORD: "addWord"
});

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      correct: 0,
      wrong: 0,
      remembered: 0,
      buttonsDisabled: true,
      currentWord: "-",
      cardFlipped: false,
      langSwitched: false,
      scaleAnim: new Animated.Value(0),
      flashCardState: flashCardState.FLASHCARD
    };

    this.correctAnswer = this.correctAnswer.bind(this);
    this.wrongAnswer = this.wrongAnswer.bind(this);
    this.flipCard = this.flipCard.bind(this);
    this.disableButtons = this.disableButtons.bind(this);
    this.enableButtons = this.enableButtons.bind(this);
    this.scaleIn = this.scaleIn.bind(this);
    this.scaleOut = this.scaleOut.bind(this);

    cardScheduler.refreshWordset().then(() => {
      this.nextWord();
    });
    this.switchLang = () => this.setState(st => { return { langSwitched: !st.langSwitched } });
  }

  scaleIn = () => {
    Animated.spring(
      this.state.scaleAnim,
      {
        toValue: 1,
        bounciness: 18,
        useNativeDriver: true
      }
    ).start();
  };

  scaleOut = () => {
    Animated.spring(
      this.state.scaleAnim,
      {
        toValue: 0,
        bounciness: 10,
        useNativeDriver: true
      }
    ).start();
  };

  componentDidMount() {
    this.focusListenerCleanup = this.props.navigation.addListener('focus', async () => {
      await cardScheduler.refreshWordset();
      this.nextWord();
    });
  }

  componentWillUnmount() {
    this.focusListenerCleanup();
  }

  render() {
    const st = this.state;
    const front = st.langSwitched ? st.currentWord.b : st.currentWord.a;
    const back = st.langSwitched ? st.currentWord.a : st.currentWord.b;
    return (
      <>
        <View style={style.fcBody}>
          <View style={style.topElementsContainer}>
            {this.state.flashCardState === flashCardState.FLASHCARD &&
              <>
                <View style={style.buttonPanelTop}>
                  <TouchableOpacity style={style.iconSwap} onPress={this.switchLang}>
                    <AntDesign name="swap" size={iconStyles.sizeIconTop} color={iconStyles.color} />
                  </TouchableOpacity>
                  <TouchableOpacity style={style.iconAdd} onPress={() => {
                    this.setState(currState => {
                      currState.flashCardState = flashCardState.ADD_WORD
                      return currState;
                    })
                  }}>
                    <AntDesign name="plus" size={iconStyles.sizeIconTop} color={iconStyles.color} />
                  </TouchableOpacity>
                </View>
                <Flashcard
                  prompt={front}
                  answer={back}
                  flipped={this.state.cardFlipped}
                  onClick={this.flipCard}
                  onEdit={() => {
                    this.setState(currState => {
                      currState.flashCardState = flashCardState.EDIT_WORD
                      return currState;
                    })
                  }}
                />
              </>
            }
            <View style={{marginTop: 20, alignItems: "center"}}>
              <Text style={style.score}>YOU KNOW</Text>
              <View style={{flexDirection: "row"}}>
                <Image
                  style={style.splash}
                  source={require('../../assets/vocab/splash_left.png')}
                />
                <Text style={style.score2}>  {this.state.remembered} / {this.state.total}  </Text>
                <Image
                  style={style.splash}
                  source={require('../../assets/vocab/splash_right.png')}
                />
              </View>
              <Text style={style.score}>WORDS</Text>
            </View>
          </View>
          <View style={style.buttonPanelBottomContainer}>
            {this.state.flashCardState === flashCardState.FLASHCARD &&
              <Animated.View style={[style.buttonPanelBottom, {transform: [{scale: this.state.scaleAnim}]}]}>
                <TouchableOpacity style={style.buttonWrong} onPress={this.wrongAnswer}>
                  <AntDesign name="close" size={iconStyles.sizeIconBottom} color={iconStyles.colorWrong}/>
                </TouchableOpacity>
                <TouchableOpacity style={style.buttonCorrect} onPress={this.correctAnswer}>
                  <AntDesign name="check" size={iconStyles.sizeIconBottom} color={iconStyles.colorCorrect}/>
                </TouchableOpacity>
              </Animated.View>
            }
          </View>
        </View>
        {(this.state.flashCardState === flashCardState.EDIT_WORD || this.state.flashCardState === flashCardState.ADD_WORD) &&
          <CardEditor closeDialogue={async () => {
              this.setState( {flashCardState: flashCardState.FLASHCARD});
              await cardScheduler.refreshWordset();
              this.nextWord();
            }}
            state={this.state.flashCardState}
            currCard={{
              card: this.state.currentWord,
              cardId: this.state.currentWord._id,
            }}
          />
        }
      </>
    );
  }

  flipCard() {
    this.setState((currState) => {
      if (!currState.cardFlipped) Speech.say(currState.currentWord.b);
      return {cardFlipped: !currState.cardFlipped}
    });
    analytics.track("cards.flip", {a: this.state.currentWord.a, b: this.state.currentWord.b})
    this.enableButtons();
  }

  disableButtons() {
    this.setState({buttonsDisabled: true});
    this.scaleOut()
  }

  enableButtons() {
    this.setState({buttonsDisabled: false});
    this.scaleIn()
  }

  correctAnswer() {
    this.setState(currState => {
      currState.correct++;
      return currState;
    });
    analytics.track("cards.correct", {a: this.state.currentWord.a, b: this.state.currentWord.b})
    cardScheduler.cardResponded(CORRECT);
    this.nextWord();
  }

  wrongAnswer() {
    this.setState(currState => {
      currState.wrong++;
      return currState;
    });
    analytics.track("cards.wrong", {a: this.state.currentWord.a, b: this.state.currentWord.b})
    cardScheduler.cardResponded(FAILED);
    this.nextWord();
  }

  nextWord() {
    this.disableButtons();
    this.setState({
      cardFlipped: false,
      remembered: cardScheduler.getNumOfWordsRemembered(),
      total: cardScheduler.countWords()
    });
    setTimeout(() => {
      this.setState({
        currentWord: cardScheduler.drawCard(),
      });
    }, 200);
  }
}

const editCardStyle = StyleSheet.create({
  card: {
    position: "absolute",
    height: 1000,
    width: "100%",
    top: 150,
    backgroundColor: palette.$accent1Tint2,
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
    padding: 25,
    zIndex: 200,
  },
  input: {
    fontSize: 20,
    width: "100%",
    height: 60,
    backgroundColor: "white",
    marginBottom: 20,
    padding: 10,
    borderRadius: 40,
    elevation: 5
  },
  headingText: {
    fontSize: 28,
    color: palette.$accent1Shade2,
    fontWeight: "bold",
  },
  topDiv: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 25
  },
  submitButton: {
    backgroundColor: palette.$yellow,
    width: "40%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    elevation: 5
  },
  submitText: {
    color: palette.$orange,
    fontSize: 20,
    fontWeight: "bold"
  }
});

function CardEditor({ closeDialogue, state, currCard }) {
  const dropDown = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
  const [foreignLanguage, setForeignLanguage] = useState(null);
  const [card, setCard] = useState(state === flashCardState.ADD_WORD ? { a: '', b: '' } : currCard.card)
  useEffect(() => {
    startAnimation();
    loadAppSetting()
  }, [])

  function startAnimation() {
    Animated.spring(
      dropDown,
      {
        toValue: 1,
        bounciness: 12,
        useNativeDriver: true
      }
    ).start();
  }

  function closeEditor() {
    Animated.timing(
      dropDown,
      {
        toValue: 0,
        duration: 200,
        useNativeDriver: true
      }
    ).start(() => closeDialogue())
  }

  return (
    <Animated.View style={[editCardStyle.card, {
      transform: [{
        translateY: dropDown.interpolate({
          inputRange: [0, 1],
          outputRange: [500, 0]
        }),
      }],
    }]}>
      <View style={editCardStyle.topDiv}>
        <Text style={editCardStyle.headingText}>
          {state === flashCardState.ADD_WORD ? "Add New" : "Edit"}
        </Text>
        <TouchableOpacity style={[style.buttonWrong]} onPress={() => closeEditor()}>
          <AntDesign name="close" size={iconStyles.sizeIconBottom} color={iconStyles.colorWrong} />
        </TouchableOpacity>
      </View>
      <WordEditor value={card.a} onChange={(val) => setCard({ ...card, a: val })} placeholder="word in your language" />
      <WordEditor value={card.b} onChange={(val) => setCard({ ...card, b: val })} placeholder={`${foreignLanguageMap[foreignLanguage] || "English"}` + " word"} />
      <View style={{ alignItems: "center", height: 50 }}>
        <TouchableOpacity style={editCardStyle.submitButton} onPress={submit}>
          <Text style={editCardStyle.submitText}>SUBMIT</Text>
        </TouchableOpacity>
      </View>
    </Animated.View>
  );

  async function loadAppSetting() {
    const appSettings = await userApi.getAppSettings();
    if (appSettings && appSettings.foreignLanguage)
      setForeignLanguage(appSettings.foreignLanguage);
  }

  async function submit() {
    try {
      if (state === flashCardState.ADD_WORD) {
        await vocabularyApi.addPair(card);
      } else {
        await vocabularyApi.updatePair(card);
      }
      analytics.track("cards.save", {...card});
      closeEditor();
    } catch (e) {
      log.error("Failed to submit new pair", e);
    }
  }
}

const foreignLanguageMap = {
  "id-ID": "Indonesian",
  "es-ES": "Spanish",
  "en-GB":"English",
  "ar": "Arabic"
};

function WordEditor(props) {
  return (
    <View>
      <TextInput style={editCardStyle.input}
        onChangeText={props.onChange}
        placeholder={props.placeholder}
        autoCapitalize="none"
        value={props.value} />
    </View>
  );
}
