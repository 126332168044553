import {config} from '../config';
import {userSession} from "./userSession";
import history from '../history/history';

export const api = {
  courseSvcRequest: apiRequest.bind(this, config.COURSE_SVC_BASE_URL),
  userSvcRequest: apiRequest.bind(this, config.USER_SVC_BASE_URL),
  notificationSvcRequest: apiRequest.bind(this, config.NOTIFICATION_SVC_BASE_URL),
  teacherSvcRequest: apiRequest.bind(this, config.TEACHER_SVC_BASE_URL),
  vocabSvcRequest: apiRequest.bind(this, config.VOCAB_SVC_BASE_URL),
  chatSvcRequest: apiRequest.bind(this, config.CHAT_SVC_BASE_URL),
  quizSvcRequest: apiRequest.bind(this, config.QUIZ_SVC_BASE_URL),
  virtualCoachSvcRequest: apiRequest.bind(this, config.VIRTUAL_COACH_SVC_BASE_URL),
  conversationSvcRequest: apiRequest.bind(this, config.CONVERSATION_SVC_BASE_URL)
};

async function apiRequest(baseUrl, method, path, payload, headers) {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
    method
  };

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  let token = await userSession.getToken();

  if (baseUrl === config.CHAT_SVC_BASE_URL) {
    token = await userSession.getPrimaryUserToken();
  }
  
  if (baseUrl !== config.VIRTUAL_COACH_SVC_BASE_URL) {
    if (token) {
      options.headers["x-cerah-auth"] = `Bearer ${token}`
    }
  }

  options.headers = {
    ...options.headers,
    ...headers   //passed-in headers override default ones
  };

  return fetch(new Request(`${baseUrl}${path}`, options))
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          history().reset('OtpLogin');
          return null;
        }
        return Promise.reject(new Error(response.statusText));
      }
      if (response.status === 204) {
        return null;
      }
      return response.json();
    });
}
