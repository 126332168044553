import { Platform } from "react-native";

export const speechToTextApi = {
  getTranscription: async (uri) => {
    const formData = new FormData();
    const headers = {
      Authorization: 'Bearer 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',
    };
    let file;
    if (Platform.OS === "web") {
      const audioBlob = await fetch(uri).then((r) => r.blob());
      file = new File([audioBlob], `${Date.now()}-recording.mp3`, { type: 'audio/mpeg' });
    } else {
      headers['Content-Type'] = 'multipart/form-data'; // Do not include multipart form data in web, it doesn't seem to work.
      file = {
        uri,
        type: 'audio/*',
        name: `${Date.now()}-recording.m4a`,
      }
    }

    formData.append('file', file);
    formData.append('model', 'whisper-1');
    formData.append('language', 'en');
    const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
      method: 'POST',
      headers,
      body: formData,
    });
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return (await response.json()).text;
  }
}