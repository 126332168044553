import {api} from "./api";
import {refreshApi} from "./refreshApi";
// import hello from "hellojs/dist/hello.all";
import {userSession} from "./userSession";

export const userApi = {
  login: async (creds) => {
    const cerahAuth = await api.userSvcRequest('POST', '/login', creds);
    await userSession.userChanged(cerahAuth);
  },

  changePwd: async ({userId, curPassword, newPassword}) => {
    await api.userSvcRequest('POST', '/pwdChange', {userId, curPassword, newPassword})
  },

  updateNotificationsToken: async (token) => {
    await api.notificationSvcRequest('POST', '/student/notificationsTokens', token);
  },

  refreshToken: async () => {
    return await refreshApi.get('/accessToken');
  },

  getUser: async () => {
    const userId = await userSession.getActiveUserId();
    if (userId) {
      return api.userSvcRequest('GET', `/users/${userId}`);
    }
  },

  getAppSettings: async () => {
    const userId = await userSession.getActiveUserId();
    if (userId) {
      if (["650459a6b822600398d3f1f9", "651ad4a91c56ea97d3348783"].includes(userId)) { //Michael & Calum
        return {
          foreignLanguage: "es",
        }
      } else if (userId === "5cab079e61d19a59173f7b3f") { //David
        return {
          foreignLanguage: "en",
        }
      } else if (userId === "5d484a588cd2ce3d029a690a") { //Sabina
        return {
          foreignLanguage: "ar",
        }
      } else {
        return {
          foreignLanguage: "en",
        }
      }
    }
  },

  updateUser: async (user) => {
    const userId = await userSession.getUserId();
    if (userId) {
      return api.userSvcRequest('PUT', `/users/${userId}`, user);
    }
  },

  requestOtp: async (phone) => {
    return api.userSvcRequest('POST', '/otpRequests', {phone});
  },

  verifyOtp: async (code, phone, countryCode) => {
    return api.userSvcRequest('POST', `/otpLogins`, {code, phone, countryCode});
  },
};
