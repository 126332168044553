import React, {useEffect, useState} from "react";
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Meetings, MeetingsScreen} from "../meetings/meetings";
import {palette} from "../style/palette";
import {PracticeSelectionScreen} from "../practice/practiceSelectionScreen";
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {analytics} from "../analytics/analytics";
import {NotificationsHistoryScreen} from "../notifications/notificationsHistory";
import {TestWebView} from "../testWebView/testWebView";
import {ChatScreen} from "../chat/chatScreen";
import {chatSession} from "cerah-chat";

const Tab = createBottomTabNavigator();

export function Home() {
  const [unreadCount, setUnreadCount] = useState(null);

  useEffect(() => {
    loadMessageCounterBadgeState();
  }, []);

  async function loadMessageCounterBadgeState() {
    await chatSession.addMessageListener(() => {
      let msgCount = chatSession.getUnreadMessagesCount();
      if (msgCount > 99)
        msgCount = "99+";
      setUnreadCount( msgCount || null);
    });
    setUnreadCount(chatSession.getUnreadMessagesCount() || null);
  }
  
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          minHeight: 56,
          paddingTop: 5
        },
        tabBarLabelStyle: {
          fontSize: 12,
          paddingBottom: 2,
          fontFamily: 'metropolis-extra-bold',
        },
        tabBarActiveTintColor: palette.$accent1Shade2,
        tabBarInactiveTintColor: palette.$accent1Shade3,
      }}>
      <Tab.Screen name="Meetings"
                  component={MeetingsScreen}
                  options={{
                    tabBarIcon: ({color, size}) =>
                      <MaterialCommunityIcons name="calendar" size={size} color={color}/>,
                    title: "Classes"
                  }}
                  listeners={{
                    focus: (e) => {
                      analytics.track("Meetings");
                    }
                  }}
      />
      <Tab.Screen name="PracticeSelection"
                  component={PracticeSelectionScreen}
                  options={{
                    tabBarIcon: ({color, size}) =>
                      <MaterialCommunityIcons name="cards" size={size} color={color}/>,
                    title: "Practice"
                  }}
                  listeners={{
                    focus: (e) => {
                      analytics.track("PracticeSelection");
                    }
                  }}
      />
      <Tab.Screen name="Test"
                  component={TestWebView}
                  options={{
                    tabBarIcon: ({color, size}) =>
                      <MaterialCommunityIcons name="comment-question" size={size} color={color}/>,
                    title: "Level Test"
                  }}
                  listeners={{
                    focus: (e) => {
                      analytics.track("Test");
                    }
                  }}
      />
      <Tab.Screen name="Notifications"
                  component={NotificationsHistoryScreen}
                  options={{
                    tabBarIcon: ({color, size}) =>
                      <MaterialCommunityIcons name="bell" size={size} color={color}/>,
                    title: "Notifications"
                  }}
                  listeners={{
                    focus: (e) => {
                      analytics.track("Notifications");
                    }
                  }}
      />
      <Tab.Screen name="Chat"
                  component={ChatScreen}
                  options={{
                    tabBarBadge: unreadCount,
                    tabBarIcon: ({color, size}) =>
                      <MaterialCommunityIcons name="chat" size={size} color={color}/>,
                    title: "Chat"
                  }}
                  listeners={{
                    focus: (e) => {
                      analytics.track("Chat");
                    }
                  }}
      />
    </Tab.Navigator>
  )
}

