import {api} from "./api";

export const quizApi = {
  getLatestLevelAttempt: async () => {
    return api.quizSvcRequest('GET', `/student/latestAttempts?testId=level`);
  },
  getDrill: async (id) => {
    return api.quizSvcRequest('GET', `/student/drills/${id}`);
  },
  getDrillInfos: async () => {
    return api.quizSvcRequest('GET', `/student/drills`);
  },
  createDrillAttempt: async (drillId) => {
    return api.quizSvcRequest('POST', `/drillAttempts`, {drillId});
  },
  submitDrillResponse: async (response) => {
    return api.quizSvcRequest('POST', `/drillAttempts/answers/`, response);
  },
  rateExercise: ({drillId, rating, comment}) => {
    return api.quizSvcRequest('POST', `/exerciseRatings`, {drillId, rating, comment});
  }
}
