import {log} from "../logger/logger";

const silence = require('../../assets/sounds/silence.mp3');
import * as ExpoSpeech from 'expo-speech';
import {userApi} from "../api/userApi";
import { Audio } from 'expo-av';

let isSpeakingCool = false;

const utterancesQueue = [];


const enableAudio = async () => {
  await Audio.setAudioModeAsync({
    playsInSilentModeIOS: true,
  })
}

enableAudio();

async function runQueue() {
  if (isSpeakingCool) return;
  if (utterancesQueue.length === 0) return;
  isSpeakingCool = true;
  const soundPromise = utterancesQueue.shift();
  try {
    const {sound} = await soundPromise;
    sound.setOnPlaybackStatusUpdate(status => {
      if (status.didJustFinish) {
        isSpeakingCool = false;
        runQueue();
      }
    });
    await sound.playAsync();
  } catch (e) {
    log.error("Couldn't play audio sound", e);
    isSpeakingCool = false;
    runQueue();
  }
}

export const Speech = {
  sayCool: async (text, language, speed) => {
    if (!text || text.trim().length === 0) return;
    try {
      const soundPromise = Audio.Sound.createAsync({
        uri: `https://api.cerah.co/utterances.mp3?text=${encodeURIComponent(text.trim())}`
      });
      utterancesQueue.push(soundPromise);
      runQueue();
    } catch (e) {
      log.error("Failed to download audio sound", e);
    }
  },
  
  say: async (text, language, speed) => {
    if (!text) return
    let lang;
    let rate = 0.75;
    if (language) {
      lang = language;
    } else {
      const appSettings = await userApi.getAppSettings();
      lang = appSettings.foreignLanguage;
    }
  
    if (speed) {
      rate = speed;
    }
  
    // what follows is critical for routing audio back through the speaker instead of the earpiece
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false
    });
    const { sound } = await Audio.Sound.createAsync(silence);
    await sound.playAsync();

    ExpoSpeech.speak(text, {
      language: lang,
      rate
    });
  },

  stopAll: () => {
    isSpeakingCool = false;
    utterancesQueue.length = 0;
    ExpoSpeech.stop();
  },
  
  isSpeakingCool: async () => {
    return utterancesQueue.length > 0 || isSpeakingCool;
  },
  
  isSpeaking: async () => {
    return ExpoSpeech.isSpeakingAsync();
  }
}