import {api} from "./api";

export const vocabularyApi = {
  retrieveWordset: async () => {
    return api.vocabSvcRequest('GET', '/my/pairs');
  },
  updatePair: async (newPair) => {
    if (!newPair._id) return;
    return api.vocabSvcRequest('PUT', `/my/pairs/${newPair._id}`, newPair);
  },
  addPair: async (newPair) => {
    return api.vocabSvcRequest('POST', `/my/pairs`, newPair);
  },
  saveResponse: async (response) => {
    return api.vocabSvcRequest('POST', `/student/pairsResponses`, response);
  }
};