import { log, LoggerWrapperComponent } from './src/logger/logger';
import React, {useEffect, useState} from 'react';
import {EmailLogin} from "./src/login/emailLogin";
import {PasswordChange} from "./src/login/passwordChange";
import {MaterialCommunityIcons, Entypo} from "@expo/vector-icons";
import * as Font from 'expo-font'
import {Platform, Text, View} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {CardStyleInterpolators, createStackNavigator} from '@react-navigation/stack';
import {Home} from "./src/home/home";
import * as Application from 'expo-application';
import {userSession} from "./src/api/userSession";
import {URLSearchParams} from 'react-native-url-polyfill';
import history, {navigationRef} from "./src/history/history";
import {pushNotifications} from "./src/expo/push/pushNotifications";
import {analytics} from "./src/analytics/analytics";
import {NotificationScreen} from "./src/notifications/notification";
import {OtpLogin} from "./src/login/otpLogin";
import {AccountPickerScreen} from "./src/account/accountPicker";
import {calculateInitialNavigationRoute} from "./src/login/appStartupHandler";
import {UserDetails} from "./src/login/userDetails";
import {injectWebCss} from "./src/style/webStyle";
import {RatingFeedback} from "./src/rating/ratingFeedback";
import {chatSession} from "cerah-chat";
import {chatApi} from "./src/api/chatApi";
import {Flashcards} from "./src/flashcards/flashcards";
import {Drill} from "./src/languageDrills/drill";
import {DrillSelectionScreen} from "./src/languageDrills/drillSelectionScreen";
import * as SplashScreen from 'expo-splash-screen';
import {ErrorPanel} from "./src/components/errorPanel";
import {Scenario} from "./src/conversation/scenario";
import {Itineraries} from "./src/conversation/itineraries";
import {Itinerary} from "./src/conversation/itinerary";
import {ConversationAssessment} from "./src/conversation/conversationAssessment";


const Stack = createStackNavigator();

pushNotifications.init();
if (Platform.OS === "web")
  injectWebCss();

let _initialRoute = null;
let _initialParams = null;

export default () => <LoggerWrapperComponent>
  <View style={{flex: 1}}>
    <App />
    <ErrorPanel timeout={3000}/>
  </View>
</LoggerWrapperComponent>

SplashScreen.preventAutoHideAsync();

function App(props) {
  const [appIsReady, setAppIsReady] = useState(false);
  console.log('__DEV__', __DEV__);

  analytics.track("App Opened");

  async function _loadAssetsAsync() {
    await Promise.all([
      Font.loadAsync(Entypo.font),
      Font.loadAsync({
        'metropolis-bold': require('./assets/fonts/Metropolis-Bold.otf'),
        'metropolis-extra-bold': require('./assets/fonts/Metropolis-ExtraBold.otf'),
        'metropolis-extra-light': require('./assets/fonts/Metropolis-ExtraLight.otf'),
        'metropolis-semi-bold': require('./assets/fonts/Metropolis-SemiBold.otf'),
        'metropolis-thin': require('./assets/fonts/Metropolis-Thin.otf'),
      }),
      Font.loadAsync(MaterialCommunityIcons.font)
    ]);
  }

  useEffect(() => {
    init()
  }, []);
  
  async function init() {
    try {
      await _loadAssetsAsync();
    } catch (e) {
      log.error("Failed to load assets", e);
    }
    if (!await userSession.getToken()) {
      try {
        await tryInstallReferrerLogin();
      } catch (e) {
        log.info("Unable to log in using install referrer token", e);
      }
    }
    if (await userSession.getToken()) {
      try {
        const supportRoomId = await chatApi.getSupportRoomId();
        await chatSession.login(supportRoomId, await userSession.getPrimaryUserToken(), (msg, extra) => log.error(msg, extra));
      } catch (e) {
        log.error("Could not log into rocket chat when student's app started", e);
      }
    }
    try {
      const {route, params} = await calculateInitialNavigationRoute();
      _initialRoute = route;
      _initialParams = params;
    } catch (e) {
      log.error("Failed to set _initialRoute", e);
    }
    setAppIsReady(true);
  }

  if (!appIsReady) {
    return null;
  }

  return (
    <NavigationContainer ref={navigationRef} onReady={navigationContainerReady}>
      <Stack.Navigator initialRouteName={_initialRoute}>
        <Stack.Screen name="OtpLogin" component={OtpLogin} options={{header: () => null}}/>
        <Stack.Screen name="UserDetails" component={UserDetails} options={{header: () => null}}/>
        <Stack.Screen name="LoginEmail" component={EmailLogin} options={{header: () => null}}/>
        <Stack.Screen name="Home" component={Home} options={{header: () => null}}/>
        <Stack.Screen name="passwordChange" component={PasswordChange}/>
        <Stack.Screen name="NotificationScreen" component={NotificationScreen} options={{headerShown: false}}/>
        <Stack.Screen name="RatingFeedback" options={{headerShown: false}}>
          {({route}) => <RatingFeedback params={route.params}/>}
        </Stack.Screen>
        <Stack.Screen name="AccountPicker" component={AccountPickerScreen} options={{headerShown: false, cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}/>
        <Stack.Screen name="FlashCards" component={Flashcards} options={{header: () => null}}/>
        <Stack.Screen name="Drill" component={Drill} options={{header: () => null}}/>
        <Stack.Screen name="DrillSelection" component={DrillSelectionScreen} options={{header: () => null}}/>
        <Stack.Screen name="Scenario" component={Scenario} options={{header: () => null}}/>
        <Stack.Screen name="ConvoAssessment" component={ConversationAssessment} options={{header: () => null}}/>
        <Stack.Screen name="Itineraries" component={Itineraries} options={{header: () => null}}/>
        <Stack.Screen name="Itinerary" component={Itinerary} options={{header: () => null}}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
  
  function navigationContainerReady() {
    history().replace(_initialRoute, _initialParams);
    setTimeout(SplashScreen.hideAsync, 0)
  }

  async function tryInstallReferrerLogin() {
    if (Platform.OS !== "android") return;
    const installReferrer = await Application.getInstallReferrerAsync();
    if (!installReferrer || !installReferrer.length) return;
    analytics.track("installReferrerLogin", {installReferrer});
    log.info(`Trying to log in with install referrer: ${installReferrer}`);
    await loginWithDeepLinkToken(`?${installReferrer}`);
  }

  async function loginWithDeepLinkToken(url) {
    const queryParams = url ? new URLSearchParams(url.split("?")[1]) : {};
    const token = queryParams && queryParams.get("tk");
    if (token && token.length) {
      analytics.track("tokenExtracted", {token});
      log.info(`Extracted deep link token: ${token}`);
      await userSession.loginWithRefreshToken(token);
      const {route, params} = await calculateInitialNavigationRoute();
      history().replace(route, params);
    }
  }
}