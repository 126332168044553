import {api} from "./api";
import {refreshApi} from "./refreshApi";

export const notificationApi = {
  acknowledgeReception: async (notifUid) => {
    return api.notificationSvcRequest('POST', '/receptionAcknowledgements', {notifUid});
  },
  getNotificationHistory: async () => {
    return api.notificationSvcRequest('GET', '/studentNotifications');
  }
};