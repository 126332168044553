import React, { useState } from 'react';
import {View, Modal, Text, TouchableOpacity, StyleSheet, Pressable} from "react-native";
import {Speech} from '../voice/speech';
import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import {gamifyPalette} from "../style/palette";
import {analytics} from '../analytics/analytics';

export function IntroductionModal({introductionData, onClose}) {
  const {instructions} = introductionData;

  function onCloseBtnPressed() {
    onClose();
    analytics.track("drill.introductionModal.closeBtnPressed");
  }

  return (
    <Modal visible={true} transparent={true}>
      <View style={style.container}>
        <View style={style.innerContainer}>
          <TouchableOpacity onPress={onCloseBtnPressed} style={style.close}>
            <Entypo name="cross" size={24} color="black" />
          </TouchableOpacity>
          <Text style={style.title}>INSTRUCTIONS: </Text>
          {instructions.map((i, idx) => {
            if (i.type === "text") {
              return <Text key={idx}>{idx + 1}. {i.text}</Text>
            } else if (i.type === "examples") {
              return (
                <View key={idx}>
                  <Text>{idx + 1}. {i.text}</Text>
                  {i.examples.map((example, index) => (
                    <Example key={example._id} example={example}/>
                  ))}
                </View>
              )
            }
          })}
        </View>
      </View>
    </Modal>
  )
}

function Example({example}) {
  return (
    <View style={style.example}>
      <View style={style.exampleInner}>
        {/* Prompt */}
        <ExampleItem id={example._id} title={"What the voice will say"} text={example.prompt} type={"prompt"}/>
        {/* Answer */}
        <ExampleItem id={example._id} title={"Your answer"} text={example.answers[0]} type={"answer"}/>
      </View>
    </View>
  );
}

function ExampleItem({id, title, text, type}) {
  const [textPlayed, setTextPlayed] = useState(false);

  function onPlay() {
    analytics.track("drill.introductionModal.examplePlayed", {promptId: id, type})
    setTextPlayed(true);
    Speech.say(text, "en", 0.9);
  }
  
  return (
    <View style={style.exampleInnerItem}>
      <View style={style.exampleItemLeft}>
        <TouchableOpacity style={style.playBtn} onPress={onPlay}>
          <Text style={style.playBtnText}>Play</Text>
          <FontAwesome5 name="play" size={14} color="white" />
        </TouchableOpacity>
      </View>
      <View style={style.exampleItemRight}>
        <Text>{title}</Text>
        {textPlayed && 
          <Text style={style.text1}>"{text}"</Text>
        }
      </View>
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    marginHorizontal: "5%"
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
    fontStyle: "italic"
  },
  text1: {
    fontWeight: 'bold'
  },
  text2: {},
  close: {
    alignSelf: 'flex-end',
    marginTop: 10
  },
  example: {
    flexShrink: 1,
    flexDirection: "row",
    paddingTop: 10,
    alignItems: "center",
    borderTopWidth: 2,
    borderTopColor: "lightgray",
    borderBottomWidth: 2,
    borderBottomColor: "lightgrey",
  },
  exampleInner: {
    flex: 1,
  },
  exampleInnerItem: {
    flexDirection: 'row',
    alignItems: "center",
    marginVertical: 5
  },
  exampleItemLeft: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  playBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    borderRadius: 10,
    backgroundColor: gamifyPalette.$colour5
  },
  playBtnText: {
    color: "white",
    marginRight: 5
  },
  exampleItemRight: {
    flex: 1
  }
});