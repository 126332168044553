import {Dimensions, StyleSheet} from 'react-native';
import {palette} from "../style/palette";

export const style = StyleSheet.create({
  textFieldIcon: {
    width: 18,
    height: 18
  },
  login: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    padding: 20,
    textAlign: "center"
  },
  loginBtn: {
    marginTop: 20
  },
  input: {
    borderBottomWidth: 1,
    fontSize: 20,
    height: 40
  },
  emailLoginInput: {
    margin: 3,
    paddingLeft: 10,
    flex: 1
  },
  emailLoginForm: {
    flex: 1,
    width: "80%",
    maxWidth: 600,
    justifyContent: "center",
  },
  emailLoginInputContainer: {
    overflow: "hidden",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    borderColor: palette.$grey,
    borderWidth: 1.2,
    borderRadius: 25,
    marginTop: 10,
    paddingLeft: 20
  },
  error: {
    textAlign: "center",
    fontSize: 20,
    color: "red"
  }
});
