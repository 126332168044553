import React, {useEffect, useState} from 'react';
import {conversationApi} from "../api/conversationApi";
import {Spinner} from "../components/spinner";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import history from "../history/history";
import {userSession} from "../api/userSession";
import {log} from "../logger/logger";
import {palette} from "../style/palette";
import {AntDesign, Entypo, FontAwesome} from "@expo/vector-icons";

let goals;

export function ConversationAssessment({route}) {
  const [assessment, setAssessment] = useState(null);
  const conversationId = route.params.conversationId;
  const scenario = route.params.scenario;
  
  useEffect(() => {
    loadState();
  }, []);

  useEffect(() => {
    goals = scenario.goals.split("\n")
  }, [scenario]);
  
  async function loadState() {
    setAssessment(await conversationApi.assessConversation(conversationId));
  }
  
  if (!assessment) {
    return (<View style={{flex: 1, justifyContent: "center"}}>
      <Spinner color="#000" size={50}/>
    </View>);
  }
  
  return (
    <View style={style.convoFeedback}>
      <View style={style.topBar}>
        <TouchableOpacity onPress={dismiss}>
          <AntDesign name="close" size={24} color={palette.$greyText}/>
        </TouchableOpacity>
      </View>
      {assessment.map((ass, idx) => <View style={style.item}>
        <View>{
          ass.answer === true ? <FontAwesome name="check-circle" size={40} color={"green"}/> :
          ass.answer === false ? <Entypo name="circle-with-cross" size={40} color={palette.$lightRed}/> :
          <Entypo name="circle-with-minus" size={40} color={palette.$orange}/>
        }</View>
        <View style={style.text}>
          <Text style={style.question}>{goals[idx]}</Text>
          <Text style={style.answer}>{ass.explanation}</Text>
        </View>
      </View>)}
    </View>
  );
  
  function dismiss() {
    history().replace("RatingFeedback",
    {
      ratingText: "Did you like the conversation?",
      subText: "How could we make it better?",
      onRatingGiven: conversationRated
    });
  }
  
  async function conversationRated(rating, comment) {
    try {
      await conversationApi.rateConversation(conversationId, rating, comment);
    } catch (e) {
      const userName = await userSession.getPrimaryUserName();
      log.error(`Error while user ${userName} was submitting a rating for the conversation ${conversationId}. ${JSON.stringify({conversationId, comment, rating})}`, e);
    }
    history().push("PracticeSelection");
  }
}

const style = StyleSheet.create({
  convoFeedback: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
  },
  topBar: {
    position: "absolute",
    top: 15,
    right: 15,
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  item: {
    padding: 20,
    borderRadius: 20,
    margin: 20,
    backgroundColor: palette.$accent1Tint2,
    flexDirection: "row"
  },
  text: {
    marginLeft: 20, 
    flexShrink: 1,
  },
  question: {
    color: palette.$mainColor2,
    fontSize: 14,
    fontWeight: "700",
    flexShrink: 1,
  },
  answer: {
    color: palette.$accent1Shade2,
    fontSize: 14,
    fontWeight: "700",
    flexShrink: 1,
  }
});