import React from 'react';
import {Animated, StyleSheet, View, Text, TouchableOpacity, TouchableWithoutFeedback, Dimensions} from 'react-native';
import {palette} from "../style/palette";


const {height: windowHeight, width: windowWidth} = Dimensions.get('window');
const width = windowWidth > windowHeight ? windowHeight * .4 : windowWidth * .6;
const height = windowWidth > windowHeight ? windowHeight * .3 : windowWidth * .6;
export const style = StyleSheet.create({
  flashcard: {
    width,
    height,
  },
  side: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "white",
    borderRadius: 30,
    flex: 1,
    justifyContent: "center",
    elevation: 7,
  },
  text: {
    position: "absolute",
    fontSize: 25,
    fontWeight: "700",
    color: palette.$accent1Shade2,
    textTransform: "uppercase",
    width: "90%",
    margin: "5%",
    textAlign: "center",
  },
  edit: {
    position: "absolute",
    alignSelf: "center",
    bottom: 15,
    zIndex: 11,
  },
  editText: {
    width: "100%",
    color: palette.$accent1,
    textAlign: "center",
  },
  reverse: {
    transform: [{rotateY: "180deg"}],
  }
});



export class Flashcard extends React.Component {
  lastFlipped = false;

  constructor(props) {
    super(props);
    this.state = {
      angle: new Animated.Value(0),
      zIndex: -10
    };
    this.onEdit = (ev) => { ev.stopPropagation(); props.onEdit(); };
  }

  render() {
    const flipped = this.props.flipped;
    if (flipped !== this.lastFlipped) {
      this.lastFlipped = flipped;
      this.animateFlip();
    }
    const angleDeg = this.state.angle.interpolate({
      inputRange: [0, 180],
      outputRange: ['0deg', '180deg']
    });
    return (
      <TouchableWithoutFeedback onPressIn={this.props.onClick}>
        <Animated.View style={[style.flashcard, {
            transform: [
              {rotateY: angleDeg},
          ]}]}>
          <View style={[style.side, {zIndex: -this.state.zIndex}]}>
            <Text style={style.text}>
              {this.props.prompt}
            </Text>
            <TouchableOpacity style={style.edit} onPress={this.onEdit}><Text style={style.editText}>Edit</Text></TouchableOpacity>
          </View>
          <View style={[style.side, style.reverse, {zIndex: this.state.zIndex}]}>
            <Text style={style.text}>
              {this.props.answer}
            </Text>
            <TouchableOpacity style={style.edit} onPress={this.onEdit}><Text style={style.editText}>Edit</Text></TouchableOpacity>
          </View>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  }

  animateFlip() {
    const flipDurationMs = 300;
    Animated.spring(
      this.state.angle,
      {
        toValue: this.lastFlipped ? 180 : 0,
        useNativeDriver: true,
        bounciness: 20
      }
    ).start();
    setTimeout(() => {this.setState(st => {return {zIndex: -st.zIndex}})}, flipDurationMs / 2);
  }
}

