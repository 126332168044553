import React, {useState, useRef} from "react";
import {
  TextInput,
  Animated,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  ScrollView
} from 'react-native';
import OneBW from '../../assets/meetingRating/1_bw.svg'
import TwoBW from '../../assets/meetingRating/2_bw.svg'
import ThreeBW from '../../assets/meetingRating/3_bw.svg'
import FourBW from '../../assets/meetingRating/4_bw.svg'
import FiveBW from '../../assets/meetingRating/5_bw.svg'
import OneCOL from '../../assets/meetingRating/1_col.svg'
import TwoCOL from '../../assets/meetingRating/2_col.svg'
import ThreeCOL from '../../assets/meetingRating/3_col.svg'
import FourCOL from '../../assets/meetingRating/4_col.svg'
import FiveCOL from '../../assets/meetingRating/5_col.svg'
import {AntDesign} from "@expo/vector-icons";
import {palette} from "../style/palette";
import history from "../history/history";

const {width: windowWidth} = Dimensions.get('window');

const ratingMapBW = {
    1: OneBW,
    2: TwoBW,
    3: ThreeBW,
    4: FourBW,
    5: FiveBW,
}

const ratingMapCol = {
    1: OneCOL,
    2: TwoCOL,
    3: ThreeCOL,
    4: FourCOL,
    5: FiveCOL
}

export function RatingFeedback({params}) {
  const {ratingText, subText, onRatingGiven} = params;
  const animation = useRef(new Animated.Value(1)).current;
  const [currRating, setCurrentRating] = useState(null);
  const [comment, setComment] = useState('')

  const CurrentRatingIcon = ratingMapCol[currRating];

  function onSubmit() {
    onRatingGiven(currRating, comment);
  }
  return (
    <ScrollView
      contentContainerStyle={{flexGrow: 1}}
      keyboardShouldPersistTaps='handled'
      style={styles.ratingScreen}
    >
      <View style={styles.topBar}>
        <TouchableOpacity onPress={() => {
          history().goBack()
        }}>
          <AntDesign name="close" size={24} color={palette.$greyText}/>
        </TouchableOpacity>
      </View>
      <Animated.View style={[styles.currentRatingContainer, {
        transform: [{scale: animation}],
      }]}>
        <View style={styles.currentRatingIcon}>
          {currRating && <CurrentRatingIcon width="100%" height="100%"/>}
        </View>
      </Animated.View>

      <Text style={styles.ratingText}>
        {ratingText}
      </Text>
      <View style={styles.ratingsRow}>
        {Object.keys(ratingMapBW).map(rating => {
          const RatingIcon = currRating === Number(rating) ? ratingMapCol[rating] : ratingMapBW[rating]
          return <TouchableOpacity style={styles.ratingIcon} key={rating} onPress={() => ratingClicked(rating)}>
            <RatingIcon width={'100%'} height={'100%'}/>
          </TouchableOpacity>
        })}
      </View>
      {
        (currRating !== null) && <View style={styles.textInputContainer}>
          <Text style={styles.commentTitle}>{subText}</Text>
          <TextInput
            style={styles.commentInput}
            onChangeText={setComment}
            value={comment}
            selectionColor={palette.$greyText}
            underlineColorAndroid="transparent"
            multiline={true}
          />
          <SubmitButton onPress={onSubmit}/>
        </View>
      }
    </ScrollView>
  );

  function ratingClicked(rating) {
    setCurrentRating(Number(rating))
    if (currRating !== Number(rating))
      scaleOut(scaleIn);
  }

  function scaleIn() {
    Animated.spring(
      animation,
      {
        toValue: 1,
        bounciness: 18,
        useNativeDriver: true,
        speed: 65
      }
    ).start();
  }

  function scaleOut(callback) {
    Animated.spring(
      animation,
      {
        toValue: 0,
        bounciness: 10,
        useNativeDriver: true,
        speed: 500
      }
    ).start(callback);
  }
}

function SubmitButton({onPress}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <TouchableOpacity
      style={styles.submitButton}
      onPress={async () => {
        setIsSubmitting(true);
        await onPress();
        setIsSubmitting(false);
      }}
    >
      {isSubmitting ?
        <ActivityIndicator color={'white'}/> :
        <Text style={styles.submitButtonText}>
          SUBMIT
        </Text>
      }
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  ratingScreen: {
    flex: 1,
    backgroundColor: 'white',
    paddingHorizontal: 40
  },
  ratingsRow: {
    flexDirection: "row",
    justifyContent: "center",
  },
  ratingIcon: {
    width: 47,
    height: 47,
    margin: 7
  },
  currentRatingIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: windowWidth * .45,
    height: windowWidth * .45,
    maxWidth: 250,
    maxHeight: 250,
  },
  ratingText: {
    fontSize: 26,
    textAlign: "center",
    fontFamily: 'metropolis-extra-bold',
    paddingVertical: 12
  },
  currentRatingContainer: {
    alignItems: 'center'
  },
  topBar: {
    paddingVertical: 15,
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  commentTitle: {
    marginTop: 30,
    marginBottom: 12,
    fontSize: 22,
    fontWeight: "700",
  },
  commentInput: {
    height: 40,
    marginHorizontal: 40,
    marginVertical: 20,
    padding: 20,
    flex: 1,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: palette.$grey,
    color: palette.$greyText,
    fontSize: 15,
    textAlignVertical: 'top',
    width: "100%"
  },
  textInputContainer: {
    flex: 1,
    alignItems: 'center',
    maxWidth: 800,
    width: "100%",
    alignSelf: "center",
  },
  submitButton: {
    backgroundColor: palette.$accent1Shade2,
    height: 55,
    borderRadius: 40,
    width: "90%",
    maxWidth: 400,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  submitButtonText: {
    color: 'white',
    fontFamily: 'metropolis-extra-bold',
    fontSize: 16
  }
});
