import * as TimSort from "timsort";
import {vocabularyApi} from '../api/vocabularyApi';
import {log} from "../logger/logger";

export const CORRECT = 0;
export const FAILED = 1;

let wordset = [];
let currWord = {};

export const cardScheduler = {
  drawCard() {
    if (wordset.length === 0) return "";
    const nextScheduledWord = wordset[0];
    if (nextScheduledWord.aNextShow < Date.now()) {
      currWord = nextScheduledWord;
    } else {
      const lastAddedWord = wordset[wordset.length - 1];
      if (lastAddedWord.aNextShow) { //the last word in the deck is not actually the last added word, but the most rehearsed word
        currWord = wordset[Math.floor(Math.random() * wordset.length)];
      } else {
        currWord = lastAddedWord;
      }
    }
    return currWord;
  },

  async cardResponded(score) {
    const now = Date.now();
    let plannedDelay = (!!currWord.aLastShown && !!currWord.aNextShow) ? currWord.aNextShow - currWord.aLastShown : 60000;
    let actualDelay = !!currWord.aLastShown ? now - currWord.aLastShown : 60000;
    let baseDelay = Math.min(actualDelay, plannedDelay);

    const randomness = Math.random() * 0.2 - 0.1;
    const deltaHit = baseDelay * 5 * (1 + randomness);
    const deltaMiss = Math.max(plannedDelay / 2, 15000);
    if (score === CORRECT) {
      currWord.aNextShow = Math.min(Math.max(currWord.aNextShow || 0, now) + deltaHit, now + 365 * 86400000 * (1 + randomness));
    } else {
      currWord.aNextShow = now + deltaMiss;
    }
    currWord.aLastShown = now;
    currWord.aNextShow = Math.floor(currWord.aNextShow);
    try {
      await vocabularyApi.saveResponse({card: currWord, correct: !score, numOfPairsKnown: this.getNumOfWordsRemembered()});
    } catch (e) {
      log.error("cannot save response", e);
    }
    sortWordset();
  },

  setVisibleSide(side) {

  },

  refreshWordset: async () => {
    wordset = await vocabularyApi.retrieveWordset() || [];
    sortArray(wordset, 'creationDate');
    sortWordset();
  },

  getNumOfWordsRemembered() {
    return wordset.filter(w => (w.aNextShow - w.aLastShown > 1800000)).length; //A word remembered is a word that can be remembered for longer than 30 minutes
  },

  countWords() {
    return wordset.length;
  }
};

function sortWordset() {
  sortArray(wordset, 'aNextShow');
}

function sortArray(pairArray, field) {
  TimSort.sort(pairArray, (p1, p2) => {  //Timsort is stable, so pairs without show time will remain sorted by creation date
    if (!p1[field] && !p2[field]) return 0;
    if (!p1[field]) return 1;
    if (!p2[field]) return -1;
    return Math.sign(p1[field] - p2[field])
  });
}