import {log} from "../logger/logger";

let _country;

export async function getGeoIpCountry() {
  try {
    const resp = await Promise.race([
      fetch("https://www.cloudflare.com/cdn-cgi/trace"),
      new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error("network timeout")), 1000);
      })
    ]);
    const text = await resp.text();
    let country = null;
    text.split("\n").forEach(line => {
      if (line.startsWith("loc=")) {
        country = line.split("loc=")[1]
      }
    });
    if (!country) {
      log.error("Failed to parse geolocation info", text);
    }
    _country = country;
  } catch (e) {
    log.error("Failed to retrieve geolocation info", e);
  }
  return _country;
}
