import * as Notifications from 'expo-notifications';
import {userApi} from "../../api/userApi";
import {log} from "../../logger/logger";
import history from "../../history/history";
import {notificationApi} from "../../api/notificationApi";
import {userSession} from "../../api/userSession";
import {Platform} from 'react-native';
import Constants from "expo-constants";
import {courseApi} from "../../api/courseApi";

export const pushNotifications = {
  init: async() => {
    if (Platform.OS === "web") return;
    let {status} = await Notifications.requestPermissionsAsync({});

    if (status !== 'granted')
      return;

    Notifications.setNotificationHandler({
      handleNotification: async (notif) => {
        if (notif?.request?.content?.data?.__notificationType === "supportChat" && history()?.getCurrentRouteName() === "Chat") {
          return {
            shouldShowAlert: false,
            shouldPlaySound: false,
            shouldSetBadge: true,
          };
        } else {
          return {
            shouldShowAlert: true,
            shouldPlaySound: false,
            shouldSetBadge: true,
          };
        }
      },
    });

    const token = await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig.extra.eas.projectId});
    const deviceToken = __DEV__ ? null : await Notifications.getDevicePushTokenAsync();

    if (await userSession.getToken()) {
      try {
        await userApi.updateNotificationsToken({
          token: token?.data,
          deviceToken: deviceToken?.data,
        });
      } catch (e) {
        log.error("Failed to send push notification token", e);
      }
    }

    Notifications.addNotificationResponseReceivedListener(pushNotifications.handleResponse);
  },

  handleResponse: async (action) => {
    const n = action?.notification?.request?.content;
    if (!n) return;
    if (n.data) {
      try { await notificationApi.acknowledgeReception(n.data.uid) } catch (e) {}
      switch (n.data.__notificationType) {
        case "supportChat":
          history().push("Chat", {});
          break;
        case "meetingsRating": {
          const notification = n.data;
          history().push("RatingFeedback", 
            {
              ratingText: `How was your class with ${notification.teacherName}?`,
              subText: "Please, tell us why...",
              onRatingGiven: meetingRated.apply(notification.meetingId, notification.teacherId)
            }
          );
          break;
        }
        default:
          history().push("NotificationScreen", {screen: "Notification", params: {title: n.title, body: n.body}});
      }
    }
  }
};


async function meetingRated(meetingId, teacherId, rating, comment) {
  try {
    await courseApi.rateMeeting({teacherId, meetingId, rating, comment});
  } catch (e) {
    const userName = await userSession.getPrimaryUserName();
    log.error(`Error while user ${userName} was submitting a rating for meeting ${meetingId} with teacher ${teacherId}. ${JSON.stringify({rating, comment})}`, e);
  }
  history().reset("Home");
}
