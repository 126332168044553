import Amplitude from './amplitude';

export const analytics = {
  track(name, options) {
    if(!options)
      return Amplitude.logEvent(name);
    else
      return Amplitude.logEvent(name, options);
  },

  async changeUserId(userId) {
    return Amplitude.setUserId(userId);
  }
}