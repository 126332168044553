import React, { useState, useEffect, useRef } from 'react';
import { Pressable, StyleSheet, Text, View, TouchableOpacity, Animated } from 'react-native';
import { Audio } from 'expo-av';
import {Spinner} from "../components/spinner";
import {gamifyPalette, palette} from "../style/palette";
import {AppButton} from '../style/commonStyle';
import { Ionicons, MaterialIcons, MaterialCommunityIcons, AntDesign } from '@expo/vector-icons';
import history from '../history/history';
import {Result} from './result';
import {displayError} from "../components/errorPanel";
import {Speech} from "../voice/speech";
import {DiffViewer} from './diffViewer';
import {IntroductionModal} from './introductionModal';
import {PulsatingCircle} from '../components/pulsatingCircle';
import {sleep} from '../sleep/sleep';
import {analytics} from '../analytics/analytics';
import {quizApi} from "../api/quizApi";
import {userSession} from "../api/userSession";
import {log} from "../logger/logger";
import {SpeechRecorder} from "../recorder/speechRecorder";

const rightSound1 = require('../../assets/sounds/woo.mp3');
const wrongSound1 = require('../../assets/sounds/wrong-answer1.mp3');
const wrongSound2 = require('../../assets/sounds/wrong-answer2.mp3');

let isNextBtnPressed = false;

let currentDrillAttemptId = null;

export function Drill({route}) {
  const {drillId} = route.params;
  const [introductionData, setIntroductionData] = useState(null);
  const [showingIntroduction, setShowingIntroduction] = useState(false)
  const [items, setItems] = useState([]);
  const [currPromptIdx, setCurrPromptIdx] = useState(0);
  const [recording, setRecording] = useState(null);
  const [transcription, setTranscription] = useState(null);
  const [currentAnswerResult, setCurrentResult] = useState();
  const [noOfCorrect, setNoOfCorrect] = useState(0);
  const [showingScore, setShowingScore] = useState(false);
  const [promptSpeaking, setPromptSpeaking] = useState();


  const answerResult = {
    CORRECT: "CORRECT",
    INCORRECT: "INCORRECT"
  }

  useEffect(() => {
    loadDrill();
  }, []);

  async function loadDrill() {
    const {items, instructions} = await quizApi.getDrill(drillId);
    setItems(items);
    setIntroductionData({instructions});
    try {
      currentDrillAttemptId = (await quizApi.createDrillAttempt(drillId))._id;
      setShowingIntroduction(true);
    } catch (e) {
      displayError("Could not load the exercise. Network problem?");
      log.error(`Failed to retrieve drill information for drill ${drillId}`, e);      
    }
  }

  if (!items.length) {
    return <View style={{flex: 1, justifyContent: "center"}}>
      <Spinner color="#000" size={50}/>
    </View>
  }
  
  return (
    <View style={style.drillContainer}>
      {showingIntroduction &&
        <IntroductionModal introductionData={introductionData} onClose={onIntroClose}/>
      }
      {showingScore &&
        <Result 
          resultText={`${noOfCorrect}/${items.length}`} 
          onClose={() => {
            setShowingScore(false);
            history().replace("RatingFeedback",
              {
                ratingText: "Did you like this exercise?",
                subText: "How could we make it better?",
                onRatingGiven: onDrillRated
              }
            );
          }}
        />
      }
      <Pressable 
        style={style.backArrow} 
        onPress={() => {
          history().goBack();
          Speech.stopAll();
        }}>
        <AntDesign name="arrowleft" size={32} color="black" />
      </Pressable>      
      <Pressable style={style.help} onPress={() => {setShowingIntroduction(true); analytics.track("drill.helpClicked")}}>
        <MaterialCommunityIcons name="help-circle" size={32} color="black" />
      </Pressable>
      <Text style={style.questionCounter}>
        {currPromptIdx + 1} / {items.length}
      </Text>
      <View style={style.promptContainer}>
        {!!transcription ?
          <Text style={style.promptText}>
            {items[currPromptIdx].prompt}
          </Text> :
          <>
            <View style={{position: "relative"}}>
              <View style={{zIndex: 1}}>
                <MaterialIcons name="volume-up" size={50} color={promptSpeaking ? "white" : gamifyPalette.$colour4}/>
              </View>
              <View style={{position: "absolute", top: -25, left: -25}}>
                {promptSpeaking && <PulsatingCircle color={gamifyPalette.$colour4} duration={1400}/>}
              </View>
            </View>
            {!promptSpeaking &&
              <TouchableOpacity 
                style={style.playAgainBtnContainer} 
                onPress={() => {
                  analytics.track("drill.playAgain", {prompt: items[currPromptIdx].prompt})
                  playPrompt(items[currPromptIdx].prompt);
                  if (recording) cancelRecording();
                }} 
                disabled={promptSpeaking}>
                <Text style={style.playAgainText}>
                  Play again
                </Text>
                <MaterialIcons name="replay" size={24} color="black" />
              </TouchableOpacity>
            }
          </>
        }
      </View>

      {currentAnswerResult &&
        <View style={[style.answer, currentAnswerResult.result === answerResult.INCORRECT ? style.wrongAnswer : '']}>
          <TouchableOpacity
              style={style.playPromptIcon}
              onPress={() => {
            playCorrectAnswer(items[currPromptIdx].answers[currentAnswerResult.matchingCorrectAnsIdx]);
            analytics.track("drill.playAnswer", {prompt: items[currPromptIdx].prompt, answer: items[currPromptIdx].answers[currentAnswerResult.matchingCorrectAnsIdx]});
          }}>
            <Ionicons name="megaphone" size={18} color="white"/>
          </TouchableOpacity>
          {currentAnswerResult.result === answerResult.CORRECT &&
            <Text style={style.answerText}>{transcription}</Text>
          }
          {currentAnswerResult.result === answerResult.INCORRECT && transcription != null &&
            <DiffViewer text1={transcription} text2={items[currPromptIdx].answers[currentAnswerResult.matchingCorrectAnsIdx]}/>
          }
        </View>
      }
      {transcription === null &&
        <View style={{...style.bottomButtonsContainer, opacity: promptSpeaking ? 0 : 1}}>
          <SpeechRecorder onRecord={checkTranscription} disabled={promptSpeaking}/>
        </View>
      }
      {transcription !== null && <AppButton style={style.button} title={currPromptIdx !== items.length - 1 ? 'Next' : 'Done'} onPress={nextPrompt} />}
    </View>
  );
  
  async function playPrompt(prompt) {
    Speech.say(prompt, "en", 0.9);
    setPromptSpeaking(true);
    while (true) {
      await sleep(200);
      if (!(await Speech.isSpeaking())) {
        setPromptSpeaking(false);
        break;
      }
    }
  }
  
  async function playCorrectAnswer(answer) {
    Speech.say(answer, "en", 0.9);
  }
  
  async function checkTranscription(answer) {
    setTranscription(answer);
    let matchingCorrectAnsIdx = 0;
    const answerIsCorrect = items[currPromptIdx].answers.some((validAnswer, idx) => {
      const isCorrect = compareStrings(answer, validAnswer);
      if (isCorrect) matchingCorrectAnsIdx = idx;
      return isCorrect;
    });
    const response = {
      result: answerIsCorrect ? answerResult.CORRECT: answerResult.INCORRECT,
      promptId: items[currPromptIdx]._id,
      drillAttemptId: currentDrillAttemptId,
      usersAnswer: answer
    }
    if (answerIsCorrect) {
      setCurrentResult({result: answerResult.CORRECT, matchingCorrectAnsIdx});
      playSound(answerResult.CORRECT);
      setNoOfCorrect(noOfCorrect => noOfCorrect + 1);
      analytics.track("drill.correct", response);
    } else {
      setCurrentResult({result: answerResult.INCORRECT, matchingCorrectAnsIdx});
      playSound(answerResult.INCORRECT);
      analytics.track("drill.incorrect", response);
    }

    try {
      await quizApi.submitDrillResponse(response);
    } catch (e) {
      log.error(`Failed to send drill response to backend for drill ${drillId}, user ${await userSession.getPrimaryUserName()}`, e);
    }

    await sleep(1000); //FIXME: hacky, we're assuming the mistake sound is shorter than 1000ms
    if (!isNextBtnPressed) playCorrectAnswer(items[currPromptIdx].answers[matchingCorrectAnsIdx]);
  }
  
  async function playSound(type) {
    try {
      if (type === answerResult.CORRECT) {
        const correctSounds = [rightSound1];
        const randomIndex = Math.floor(Math.random() * correctSounds.length);
        const { sound } = await Audio.Sound.createAsync(correctSounds[randomIndex]);
        await sound.playAsync();
      } else {
        const wrongSounds = [wrongSound1, wrongSound2];
        const randomIndex = Math.floor(Math.random() * wrongSounds.length);
        const { sound } = await Audio.Sound.createAsync(wrongSounds[randomIndex]);
        await sound.playAsync();
      }
    } catch (e) {
      log.error("Failed to play result sound", e);
    }
  }
  
  async function nextPrompt() {
    isNextBtnPressed = true;
    Speech.stopAll();
    if (currPromptIdx + 1 === items.length) {
      setShowingScore(true);
      analytics.track("drill.complete", {noOfCorrect, total: items.length});
    } else {
      analytics.track("drill.nextPrompt", {prompt: items[currPromptIdx].prompt, currPromptIdx, noOfCorrect, total: items.length});
      setTranscription(null);
      setCurrentResult(null);
      setCurrPromptIdx(currIdx => currIdx + 1);
      await sleep(500);
      playPrompt(items[currPromptIdx + 1].prompt);
    }
    isNextBtnPressed = false;
  }
  
  
  async function onIntroClose() {
    Speech.stopAll();
    setShowingIntroduction(false);
    await sleep(500);
    playPrompt(items[currPromptIdx].prompt);
  }
  
  async function onDrillRated(rating, comment) {
    try {
      await quizApi.rateExercise({drillId, rating, comment});
    } catch (e) {
      const userName = await userSession.getPrimaryUserName();
      log.error(`Error while user ${userName} was submitting a rating for the drill ${drillId}. ${JSON.stringify({drillId, rating, comment})}`, e);
    }
    history().push("DrillSelection");
  }
  
}

function compareStrings(str1, str2) {
  const regex = /[^A-Za-z0-9]/g;
  str1 = str1.replace(regex, '').toLowerCase();
  str2 = str2.replace(regex, '').toLowerCase();
  return (str1 === str2)
}

const style = StyleSheet.create({
  drillContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    backgroundColor: gamifyPalette.$neutral1,
    justifyContent: "space-between",
    padding: 20
  },
  questionCounter: {
    fontSize: 28,
    fontWeight: "bold",
    color: palette.$accent1Shade3
  },
  promptContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: 500,
    maxWidth: 500,
    height: "40%",
    backgroundColor: gamifyPalette.$colour3,
    borderRadius: 20,
    width: "100%",
    position: "relative"
  },
  playAgainBtnContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: 'absolute',
    bottom: 20,
    padding: 12,
    borderRadius: 30,
    backgroundColor: 'white',
  },
  playAgainText: {
    fontWeight: "bold",
    color: gamifyPalette.$colour4,
    fontSize: 16
  },
  nextBtnContainer: {
    display: "flex",
    backgroundColor: "red",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%"
  },
  promptText: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    color: gamifyPalette.$neutral1
  },
  bottomButtonsContainer: {
    width: "100%",
    maxWidth: 500,
    position: "relative"
  },
  answer: {
    backgroundColor: gamifyPalette.$colour3,
    width: "100%",
    maxWidth: 500,
    borderRadius: 30,
    color: "white",
    padding: 16,
    flexDirection: "row",
    alignItems: "center"
  },
  wrongAnswer: {
    backgroundColor: gamifyPalette.$neutral1
  },
  answerText: {
    fontSize: 18,
    color: gamifyPalette.$neutral1,
    flexShrink: 1,
  },
  playPromptIcon: {
    backgroundColor: gamifyPalette.$colour4,
    padding: 10,
    borderRadius: 24,
    marginRight: 10,
  },
  help: {
    position: "absolute",
    top: 10,
    right: 10
  },
  backArrow: {
    position: "absolute",
    top: 10,
    left: 10
  },
  button: {
    backgroundColor: gamifyPalette.$colour4
  }
})