import React, {FlatList, StyleSheet, Text, TouchableOpacity} from "react-native";
import {useEffect, useState} from "react";
import {conversationApi} from "../api/conversationApi";
import history from "../history/history";

const style = StyleSheet.create({
  scenario: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 16,
    margin: 16
  }
});

export function Itineraries() {
  const [itineraries, setItineraries] = useState([]);
  
  useEffect(() => {
    loadItineraries();
  }, [])
  
  async function loadItineraries() {
    setItineraries(await conversationApi.getAllItineraries());
  }
  
  return (
    <FlatList data={itineraries}
              keyExtractor={item => item._id.toString()}
              renderItem={({ item, index }) => (
                <TouchableOpacity onPress={() => onItinerarySelected(item, index)} style={style.scenario}>
                  <Text>{item.name}</Text>
                </TouchableOpacity>
              )}/>
  );
  
  function onItinerarySelected(item, index) {
    history().push("Itinerary", {itinerary: item})
  }
}