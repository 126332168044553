import React from "react";
import {userApi} from "../api/userApi";
import {style} from "./loginStyle";
import {View, Platform, Text, TextInput, ActivityIndicator, Image, Dimensions, ScrollView} from 'react-native';
import {palette} from "../style/palette";
import {AppButton, commonStyles} from "../style/commonStyle";
import {Spinner} from "../components/spinner";
import {log} from "../logger/logger";
import {calculateInitialNavigationRoute} from "./appStartupHandler";
import history from "../history/history";

const windowHeight = Dimensions.get('window').height;

export class EmailLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
    this.submitClicked = this.submitClicked.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
    this.pwdChanged = this.pwdChanged.bind(this);
  }

  render() {
    return (
      <ScrollView>
        <View style={[commonStyles.page, Platform.OS === "web" ? {flex: "auto"} : {}]}>
          <View style={style.emailLoginForm}>
            <Text style={[commonStyles.headingText, {
              paddingTop: windowHeight * .12,
              paddingBottom: windowHeight * .06
            }]}>Login</Text>
            {this.state.error &&
              <View><Text style={style.error}>{this.state.error}</Text></View>
            }
            <View>
              <View style={style.emailLoginInputContainer}>
                <Image
                  style={style.textFieldIcon}
                  source={require('../../assets/icon_email.png')}
                />
                <TextInput style={style.emailLoginInput}
                           onChangeText={this.emailChanged}
                           keyboardType="email-address"
                           autoCapitalize="none"
                           placeholder="Email"/>

              </View>
              <View style={style.emailLoginInputContainer}>
                <Image
                  style={style.textFieldIcon}
                  source={require('../../assets/icon_lock.png')}
                />
                <TextInput style={style.emailLoginInput}
                           onChangeText={this.pwdChanged}
                           secureTextEntry={true}
                           placeholder="Password"/>
              </View>

            </View>
            <View style={{alignItems: "center", paddingTop: windowHeight * .07}}>
              <AppButton onPress={this.submitClicked} title={this.state.loggingIn ? "" : "Log In"}>
                {this.state.loggingIn && <Spinner/>}
              </AppButton>
              <Text style={commonStyles.infoText}>Don't have email and password?{"\n"}
                <Text onPress={() => history().push("OtpLogin")}
                      style={{color: palette.$accent1Shade2}}>Log in with phone</Text>
              </Text>
            </View>
          </View>
          <Image
            style={commonStyles.backgroundDoodle}
            source={require('../../assets/backgroundDoodle.png')}
          />
        </View>
      </ScrollView>
    );
  }

  emailChanged(email) {
    this.setState({email});
  }

  pwdChanged(password) {
    this.setState({password});
  }

  async submitClicked() {
    if (this.state.loggingIn) return;
    this.setState({loggingIn: true});
    try {
      await userApi.login({email: this.state.email.trim(), password: this.state.password});
    } catch (e) {
      const body = e._bodyText && JSON.parse(e._bodyText);
      log.error("Failed to log in", JSON.stringify(e));
      const msg = (body && body.message) || "Could not log in";
      this.setState({error: msg});
      return;
    } finally {
      this.setState({loggingIn: false});
    }
    const {route, params} = await calculateInitialNavigationRoute();
    history().reset(route, params);
  }
}
