import React, {useEffect, useState} from "react";
import {StyleSheet, Text, ScrollView} from "react-native";
import {createStackNavigator} from "@react-navigation/stack";
import {headerBarOptions} from "../style/commonStyle";
import {getFirstName, userSession} from "../api/userSession";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {palette} from "../style/palette";
import { TouchableOpacity } from "react-native-gesture-handler";
import history from '../history/history';

const Stack = createStackNavigator();

export function AccountPickerScreen() {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="AccountPicker"
                    component={AccountPicker}
                    options={{title: "Switch account"}}
      />
    </Stack.Navigator>
  )
}

function AccountPicker(props) {
  const [relatedUsers, setRelatedUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    const primaryUserId = await userSession.getUserId();
    const primaryUserName = await userSession.getPrimaryUserName();
    const relationsTokens = await userSession.getRelationsTokens();
    const activeUserId = await userSession.getActiveUserId();

    const relatedUsers = [{_id: primaryUserId, name: primaryUserName}];
    Object.keys(relationsTokens).map(userId => {
      relatedUsers.push({_id: userId, name: getFirstName(relationsTokens[userId].name)});
    });
    setRelatedUsers(relatedUsers.filter(user => user._id !== activeUserId));
  }

  async function switchUser(selectedUserId) {
    await userSession.switchUser(selectedUserId);
    history().reset('Home');
  }

  return (
    <ScrollView style={styles.studentListContainer}>
      {
        relatedUsers.map((user) => (
          <TouchableOpacity key={user._id}
                            style={styles.listItem}
                            onPress={() => switchUser(user._id)}>
              <Text style={styles.listItemText}>
                {user.name}
              </Text>
              <MaterialCommunityIcons name="chevron-right" size={32} color={palette.$grey}/>
          </TouchableOpacity>
        ))
      }
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  studentListContainer: {
    flex: 1,
    backgroundColor: 'white'
  },
  listItem: {
    flexDirection: 'row',
    paddingVertical: 20,
    marginHorizontal: 20,
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: palette.$grey
  },
  listItemText: {
    fontSize: 24,
    marginLeft: 10
  }
})
