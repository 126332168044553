import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, FlatList, TouchableOpacity } from 'react-native';
import history from '../history/history';
import {createStackNavigator} from "@react-navigation/stack";
import {headerBarOptions} from "../style/commonStyle";
import { Entypo } from '@expo/vector-icons'; 
import {gamifyPalette} from '../style/palette';
import {analytics} from '../analytics/analytics';
import {quizApi} from "../api/quizApi";

const Stack = createStackNavigator();

export const DrillSelectionScreen = () => {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="Exercises"
                    component={DrillsList}
                    options={{
                      title: "Exercises",
                    }}
      />
    </Stack.Navigator>
  );
}

const DrillsList = () => {
  const [drills, setDrills] = useState([]);

  useEffect(() => {
    (async () => setDrills(await quizApi.getDrillInfos()))();
  }, []);

  function onSelect(drillItem, index) {
    analytics.track("exercise.selectedDrill", {drillId: drillItem._id, drillPositionInList: index});
    history().push("Drill", {drillId: drillItem._id})
  }

  const renderItem = ({ item, index }) => (
    <TouchableOpacity style={styles.item} onPress={() => onSelect(item, index)}>
      <View style={styles.icon}>
        <Entypo name="modern-mic" size={24} color="white" />
      </View>
      <View style={styles.itemContent}>
        <Text style={styles.title}>Exercise: {index + 1}</Text>
        <Text style={styles.subtitle}>{item.title}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={drills}
        renderItem={renderItem}
        keyExtractor={(item) => item._id.toString()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F7F7F7',
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 16,
    marginBottom: 16,
  },
  icon: {
    padding: 10,
    backgroundColor: gamifyPalette.$colour5,
    borderRadius: 24,
    marginRight: 20
  },
  itemContent: {
    flexShrink: 1
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    color: "grey"
  }
});