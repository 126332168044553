import {api} from "./api";

export const conversationApi = {
  startConversation: async ({name, language, scenarioId}) => {
    return api.conversationSvcRequest("POST", "/conversations", {clientName: name, language, scenarioId});
  },
  
  getReply: async (conversationId, studentWords) => {
    return api.conversationSvcRequest("PATCH", `/conversations/${conversationId}`, {reply: studentWords});
  },
  
  rateConversation: async (conversationId, rating, comment) => {
    return api.conversationSvcRequest("POST", `/conversationRatings`, {
      conversationId, rating, comment
    });
  },
  
  getAllItineraries: async () => {
    return api.conversationSvcRequest("GET", "/student/itineraries");
  },  
  
  getItineraryById: async (itineraryId) => {
    return api.conversationSvcRequest("GET", `/student/itineraries/${itineraryId}`);
  },
  
  getScenarioById: async (scenarioId) => {
    return api.conversationSvcRequest("GET", `/student/scenarios/${scenarioId}`);
  },
  
  assessConversation: async (conversationId) => {
    return api.conversationSvcRequest("POST", `/conversationAssessments`, {conversationId});
  }
};
