import React from 'react';
import { StackActions } from '@react-navigation/native';

export const navigationRef = React.createRef();

export default () => {
  return {
    push(route, params) {
      navigationRef.current?.navigate(route, params);
    },

    replace(route, params) {
      navigationRef.current?.dispatch(StackActions.replace(route, params));
    },

    reset(route, params) {
      navigationRef.current.reset({
        index: 0,
        routes: [{name: route, params}],
      });
    },

    goBack() {
      navigationRef.current?.goBack();
    },

    getCurrentRouteName() {
      return navigationRef?.current?.getCurrentRoute()?.name;
    }
  }
};
